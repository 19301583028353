import { observable, action, reaction, decorate, computed, comparer, extendObservable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { map } from 'itertools';

const itemEquals = ({ key: oldKey, value: oldValue }, { key, value, ignoreUpdate }) => {
  // The ignoreUpdate flag allows us to implement silent updates.
  if (ignoreUpdate) return true;
  return comparer.structural({ key: oldKey, value: oldValue }, { key, value });
};

class BaseStore {
  constructor() {
    this.raw = observable.map();
    this.silentUpdate = new Set();

    this.changeOptions = {};

    this.get = createTransformer(id => this._get(id));
  }

  // Private functions

  _generateItem(itemConfig) {
    // Core of this library. Creates an observable item from the fieldMapper
    // configuration.
    // Also allows us to make sure the changes silent changes are ignored.
    //
    // This is an implementation detail, do not override this function.

    const newItem = observable(this.fieldMapper.new(itemConfig, this), null);
    const extractItemChanges = () => {
      // Function that binds the onChange callback with changes of the item.
      // Also swallows changes when started from a silent update.
      const { key, value } = this.fieldMapper.extract(newItem);
      if (this.silentUpdate.has(key)) {
        this.silentUpdate.delete(key);
        return { key, value, ignoreUpdate: true };
      }
      return { key, value };
    };
    if (this.onChange) {
      reaction(extractItemChanges, ({ key, value }) => this.onChange(key, value), {
        equals: itemEquals,
        ...this.changeOptions,
      });
    }
    return newItem;
  }

  _get(itemId) {
    // Default way to create a view over an item from the store.
    // Override this function if you want to customise the view.
    if (!this.raw.has(itemId)) return undefined;
    const self = this;
    const view = observable({
      raw: this.raw.get(itemId),
      delete_: function delete_() {
        self.delete(itemId);
      },
    });

    extendObservable(view, this.fieldMapper.viewDefinition(view.raw));
    if (this.itemView) {
      const [viewExtension, viewExtensionConfig] = this.itemView(view);
      extendObservable(view, viewExtension, viewExtensionConfig);
    }
    return view;
  }

  // Bulk operations

  load(items) {
    // Import a list of items and do not consider them as changes
    items.forEach(item => this.insert(item, true));
  }

  refresh(items) {
    // Syncronise a list of items and do not consider it as changes. Note that
    // items will not be deleted
    items.forEach(item => this.upsert(item, true));
  }

  dump() {
    // Create a pure json rempresentation of the store. This representation can
    // be loaded with load.
    return [...this.raw.values()].map(value => this.fieldMapper.extract(value, true).value);
  }

  get all() {
    // Return a list of a view over all the elements from the collection
    return map(this.raw.keys(), key => this.get(key));
  }

  map(mapper) {
    return map(this.all, key => mapper(key));
  }

  // Single item operations

  has(itemId) {
    // Check this existence of an item
    return this.raw.has(itemId);
  }

  insert(item, silent = false) {
    // Add an element in the store.
    // If silent, the "onChange" function will not be triggered.
    const newItem = this._generateItem(item);
    this.raw.set(newItem.id, newItem);
    if (!silent && this.onChange) {
      const { key, value } = this.fieldMapper.extract(newItem);
      this.onChange(key, value);
    }
  }

  delete(itemId, silent = false) {
    // Remove an element from the store.
    // If silent, the "onChange" function will not be triggered.
    const wasRemoved = this.raw.delete(itemId);
    if (wasRemoved && !silent && this.onChange) {
      this.onChange(itemId, null);
    }
    return wasRemoved;
  }

  update(itemUpdates, silent = false) {
    // Update an element in the store.
    // If silent, the "onChange" function will not be triggered.
    const { id } = itemUpdates;
    const item = this.raw.get(id);
    if (silent) {
      this.silentUpdate.add(id);
    }
    this.fieldMapper.update(item, itemUpdates, silent);
  }

  upsert(item, silent = false) {
    if (this.raw.has(item.id)) {
      this.update(item, silent);
    } else {
      this.insert(item, silent);
    }
  }
}

export default decorate(BaseStore, {
  silentUpdate: observable,
  load: action.bound,
  refresh: action.bound,
  update: action.bound,
  upsert: action.bound,
  insert: action.bound,
  all: computed,
});
