import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  isSameDay,
  formatDistance,
  parse,
  isDate,
  isValid,
  format,
} from 'date-fns';

const ADD_UNITS = {
  day: addDays,
  days: addDays,
  week: addWeeks,
  weeks: addWeeks,
  month: addMonths,
  months: addMonths,
  year: addYears,
  years: addYears,
};
export const addToDate = function addToDate(date, amount, unit) {
  return ADD_UNITS[unit](date, amount);
};

const SUB_UNITS = {
  day: subDays,
  days: subDays,
  week: subWeeks,
  weeks: subWeeks,
  month: subMonths,
  months: subMonths,
  year: subYears,
  years: subYears,
};
export const subToDate = function subToDate(date, amount, unit) {
  return SUB_UNITS[unit](date, amount);
};

export const formatDayFromNow = date => {
  const now = new Date();
  if (isSameDay(date, now)) return 'today';
  if (isSameDay(date, addDays(now, 1))) return 'tomorrow';
  if (isSameDay(date, subDays(now, 1))) return 'yesterday';
  return formatDistance(date, now, { addSuffix: true });
};

const FORMAT = 'yyyy-MM-dd';

export const parseStdDate = date => {
  if (!date) return date;
  if (isDate(date)) return date;
  try {
    const parsed = parse(date, FORMAT, new Date('2000-01-01T12:10:00Z'));
    if (isValid(parsed)) return parsed;
    return '';
  } catch (e) {
    console.error('Could not parse date', date);
    return '';
  }
};

export const formatStdDate = val => {
  if (!val) return val;
  return format(val, FORMAT);
};
