import { decorate, observable, action, computed } from 'mobx';

import { any } from 'itertools';
import { addToDate, subToDate } from '../date-helpers';

class PatientVisitsInner {
  constructor(visitId, visits, history) {
    this.visitId = visitId;
    this.visits = visits;
    this.history = history;
  }

  get status() {
    if (this.current) return 'planned';
    if (!this.current && this.canEdit) return 'to plan';
    if (!this.current && !this.canEdit) return 'waiting';
    return 'error';
  }

  get visit() {
    return this.visits.get(this.visitId);
  }

  get previousVisitName() {
    if (!this.visit.after_visit || !this.visits.has(this.visit.after_visit)) {
      return '';
    }
    return this.visits.get(this.visit.after_visit).name;
  }

  get previousVisit() {
    if (!this.visit.after_visit || !this.visits.has(this.visit.after_visit)) {
      return null;
    }

    const previousVisit = this.history.get(this.visit.after_visit);
    if (!previousVisit) {
      return null;
    }

    return previousVisit;
  }

  get optional() {
    return this.visit.optional;
  }

  get canEdit() {
    if (this.optional) return true;
    if (!this.visit.after_visit) return true;
    return !!this.previousVisit;
  }

  get info() {
    return this.history.get(this.visitId);
  }

  get current() {
    if (!this.info || !this.info.visitDate) return undefined;
    return this.info;
  }

  get hasPlannedDependants() {
    return any(this.history.all, planned => {
      const plannedVisit = this.visits.get(planned.visitId);
      if (!plannedVisit) return false;
      return plannedVisit.after_visit === this.visitId;
    });
  }

  get window() {
    if (!this.visit.after_visit && !this.visits.has(this.visit.after_visit)) {
      return null;
    }

    if (!this.previousVisit || !this.previousVisit.visitDate) {
      return null;
    }

    if (this.optional) {
      return null;
    }

    const { unit: delayUnit = 2, value: delayValue = 'week' } = this.visit.after_delay;
    const date = addToDate(this.previousVisit.visitDate, delayValue, delayUnit);

    const { unit: windowUnit = 0, value: windowValue = 'week' } = this.visit.window;

    return {
      start: subToDate(date, windowValue, windowUnit),
      middle: date,
      end: addToDate(date, windowValue, windowUnit),
    };
  }

  get isEmpty() {
    if (!this.info) return null;
    return !this.info.checklist.size && !this.info.comment && !this.info.visitDate;
  }

  setDate(newDate) {
    if (!newDate && (!this.current || this.hasPlannedDependants)) {
      return;
    }

    if (!this.info) {
      this.history.insert({
        // We rely on the fact that the history visit has the same id than the
        // visit id - this is mostly a speed issue (and making sure we have
        // everything consistant as well).
        id: this.visit.id,
        visitDate: newDate,
        visitId: this.visit.id,
      });
      return;
    }

    this.info.visitDate = newDate;
    if (this.isEmpty) this.info.delete_();
  }

  toggleChecklist(item) {
    if (!this.info) {
      this.history.insert({
        id: this.visit.id,
        visitId: this.visit.id,
        checklist: [item],
      });
      return;
    }

    if (this.info.checklist.has(item)) {
      this.info.checklist.delete(item);
    } else {
      this.info.checklist.add(item);
    }

    if (this.isEmpty) this.info.delete_();
  }

  setComment(comment) {
    if (!this.info) {
      this.history.insert({
        id: this.visit.id,
        visitId: this.visit.id,
        comment,
      });
      return;
    }

    this.info.comment = comment;
    if (this.isEmpty) this.info.delete_();
  }
}

const PatientVisit = decorate(PatientVisitsInner, {
  history: observable,
  setDate: action.bound,
  toggleChecklist: action.bound,
  setComment: action.bound,
  window: computed,
  hasPlannedDependants: computed,
  current: computed,
  canEdit: computed,
  previousVisit: computed,
  previousVisitName: computed,
  visit: computed,
  status: computed,
  isEmpty: computed,
});
export default PatientVisit;
