import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';

import { useStore } from '../state/store';

const HISTORY_LENGTH = 5;

export default () => {
  const store = useStore();
  const history = store.studiesSeen;

  const currentPage = history && history.length && history[0];

  const updateHistory = useCallback(
    studyId => {
      let newHistory = Array.from(history);

      if (!studyId) return;
      if (currentPage === studyId) return;
      if (newHistory.includes(studyId)) {
        newHistory = newHistory.filter(h => h !== studyId);
      }

      newHistory.unshift(studyId);
      newHistory = newHistory.slice(0, HISTORY_LENGTH);

      if (isEqual(newHistory, history)) return;
      history.replace(newHistory);
    },
    [history, currentPage]
  );

  return [
    history
      .filter(studyId => store.studies.has(studyId))
      .map(studyId => store.studies.get(studyId)),

    updateHistory,
    currentPage,
  ];
};
