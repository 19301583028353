import React from 'react';
import styled from 'styled-components';
import { color } from '../theme';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

export const LoadingWrapper = styled.div`
  min-height: calc(100vh - 100px);
  width: 100%;
  font-size: 30px;
  padding: 40px;

  color: ${color(null, 1)};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = ({ ...props }) => <LoadingWrapper {...props}>Loading...</LoadingWrapper>;

export default Loading;

export const WhenLoaded = withRouter(
  inject('GlobalUI')(
    observer(({ children, GlobalUI }) => (GlobalUI.ready ? children : <Loading />))
  )
);
