import { Field } from './fieldMapper';
import BaseStore from './store';

export default class FieldStore extends Field {
  constructor(createStore, { val = [], deep = true, ...props } = {}) {
    super({ val, deep, ...props });
    this.createStore = createStore;
  }

  create(fromVal = this.defaultsVal, context) {
    if (this.createFcn) return this.createFcn(fromVal, context);

    const store = this.createStore(context);
    store.load(fromVal);
    return store;
  }

  extract(store) {
    if (this.extractFcn) return this.extractFcn(store);
    return store.dump();
  }

  update(newVal, previousVal, silent = false) {
    if (this.updateFcn) return this.updateFcn(newVal, previousVal);
    newVal.forEach(v => previousVal.upsert(v, silent));
    return previousVal;
  }
}

export class BaseFieldStore extends BaseStore {
  constructor(parentContext) {
    super();
    this.parentInstanceId = parentContext.instanceId;
    this.parentStore = parentContext.parent;
    this.sourceField = parentContext.sourceField;
  }

  get parent() {
    return this.parentStore.get(this.parentInstanceId);
  }

  onChange() {
    if (!this.parentStore.onChange) return;
    this.parentStore.onChange(this.parentInstanceId, { [this.sourceField]: this.dump() });
  }
}
