import React from 'react';

import { Field } from 'formik';
import { StyledInput } from '../components/FormStyles.jsx';
import { Label, Line } from '../components/InfoBox.jsx';

export default () => (
  <>
    <Line>
      <Label>Name</Label>
      <StyledInput autoFocus as={Field} type="text" name="name" />
    </Line>
    <Line>
      <Label>Email</Label>
      <StyledInput as={Field} type="text" name="email" />
    </Line>
    <Line>
      <Label>Phone</Label>
      <StyledInput as={Field} type="text" name="phone" />
    </Line>
    <Line>
      <Label>Patient Id</Label>
      <StyledInput as={Field} type="text" name="patient_id" />
    </Line>
    <Line>
      <Label>Comment</Label>
      <StyledInput as={Field} type="text" name="comments" />
    </Line>
  </>
);
