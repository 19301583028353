import React from 'react';
import styled from 'styled-components/macro';
import querystring from 'querystring';

import { lighten } from 'polished';

import { compose } from 'ramda';
import { observer, inject } from 'mobx-react';
import { withRouter, Redirect, Switch, Route } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';

import { Button, CancelButton, ButtonBar } from '../components/Button.jsx';

import { color } from '../theme';

import { Input } from '../components/form-fields.jsx';

import { requestLogin } from '../service/login';
import urlTo from '../urls';

const ModalButtonBar = styled(ButtonBar)`
  margin-top: 20px;
  justify-content: center;
`;

const OneLineForm = styled(Form)`
  > *:not(:last-child) {
    margin-right: 15px;
  }
  display: flex;

  width: 100%;
  margin-bottom: 20px;
`;

const InputField = styled(Input.withComponent(Field))`
  width: 100%;
`;

const ErrorBox = styled.div`
  margin-top: 20px;
  padding: 20px;

  background-color: #f0cbcb;
  border-radius: 2px;
`;

const Wrapper = styled.div`
  max-width: 400px;
  margin: auto;
`;

const InfoBox = styled(ErrorBox)`
  background-color: ${compose(
    lighten(0.3),
    color(1)
  )};
`;

const LogOut = withRouter(
  inject('GlobalUI')(
    observer(({ GlobalUI, history }) => (
      <React.Fragment>
        <div>Are you sure you want to log out?</div>
        <ModalButtonBar>
          <CancelButton onClick={() => history.push('/studies')}>Cancel</CancelButton>
          <Button onClick={GlobalUI.backend.logout}>Log Out</Button>
        </ModalButtonBar>
      </React.Fragment>
    ))
  )
);

const LoginForm = ({ errors, status, isSubmitting }) => (
  <div>
    <h1>Welcome to your planning tool</h1>
    <p>Enter your email or single use password</p>
    <OneLineForm>
      <InputField type="text" name="email" placeholder="email or password" />
      <Button disable={isSubmitting} type="submit">
        {isSubmitting ? 'Connecting...' : 'Request'}
      </Button>
    </OneLineForm>
    {errors.email && <ErrorBox>{errors.email}</ErrorBox>}
    {status === 'sent' && <InfoBox>Single use password sent</InfoBox>}
  </div>
);

const setLogin = (token, actions, backend, history) =>
  backend
    .login(token)
    .then(() => {
      let next = '/';
      if (history.location.search) {
        const qs = querystring.parse(history.location.search.replace(/^\?/, ''));
        next = qs.next || '/studies';
      }

      history.push(next);
    })
    .catch(() => {
      actions.setErrors({ email: 'Invalid password' });
    });

const askToken = (email, lang, actions) =>
  requestLogin(email, lang)
    .then(() => {
      actions.resetForm();
      actions.setStatus('sent');
    })
    .catch(() => {
      actions.setErrors({ email: 'Invalid email' });
    });

const LogIn = withRouter(
  inject('GlobalUI')(
    observer(({ GlobalUI, history }) => (
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          let query;
          if (values.email.indexOf('@') === -1) {
            query = setLogin(values.email, actions, GlobalUI.backend, history);
          } else {
            query = askToken(values.email, GlobalUI.lang, actions);
          }
          query.then(() => actions.setSubmitting(false));
        }}
        component={LoginForm}
      />
    ))
  )
);

const Authentication = withRouter(
  inject('GlobalUI')(
    observer(({ GlobalUI }) => {
      const Inner = GlobalUI.backend.loggedIn ? LogOut : LogIn;
      return (
        <Wrapper>
          <Inner />
        </Wrapper>
      );
    })
  )
);
export default Authentication;

export const WhenLoggedIn = withRouter(
  inject('GlobalUI')(
    observer(({ children, GlobalUI, history }) =>
      GlobalUI.backend.loggedIn ? (
        children
      ) : (
        <Switch>
          <Route path={urlTo('login')} component={Authentication} />
          <Redirect to={urlTo('login', { next: history.location.pathname })} />
        </Switch>
      )
    )
  )
);
