import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { Provider } from 'mobx-react';

import { Router, Route, withRouter, Switch } from 'react-router-dom';

import theme from './theme';

import RawLayout from './layout/Layout.jsx';
import { HeaderMenu, SideMenu } from './components/Menus.jsx';

import ScreenBody from './components/ScreenBody.jsx';
import Footer from './components/Footer.jsx';
import { WhenLoaded } from './components/Loading.jsx';

import Studies from './screens/Studies.jsx';
import Study from './screens/Study.jsx';
import Patient from './screens/Patient.jsx';
import Visits from './screens/Visits.jsx';
import Authentication, { WhenLoggedIn } from './screens/Authentication.jsx';

import { StoreContext, store } from './state/store';
import history from './state/history';
import urlTo from './urls';

const Layout = withRouter(RawLayout);

export default () => (
  <ThemeProvider theme={theme}>
    <Provider GlobalUI={store}>
      <StoreContext.Provider value={store}>
        <Router history={history}>
          <Layout headerMenu={<HeaderMenu />} sideMenu={<SideMenu />} footer={<Footer />}>
            <ScreenBody>
              <WhenLoaded>
                <WhenLoggedIn>
                  <Switch>
                    <Route path="/" exact component={Studies} />
                    <Route path={urlTo('studies')} exact component={Studies} />
                    <Route path={urlTo('study')} exact component={Study} />
                    <Route path={urlTo('visits')} exact component={Visits} />
                    <Route path={urlTo('patient')} exact component={Patient} />
                    <Route path={urlTo('login')} component={Authentication} />
                  </Switch>
                </WhenLoggedIn>
              </WhenLoaded>
            </ScreenBody>
          </Layout>
        </Router>
      </StoreContext.Provider>
    </Provider>
  </ThemeProvider>
);
