import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { observer } from 'mobx-react-lite';

import { desaturate, lighten } from 'polished';
import { compose } from 'ramda';

import useOutsideCallback from '../quarolib/hooks/useOutsideCallback';

import { InlineButton } from './Button.jsx';
import { StyledInput, StyledSelect } from './FormStyles.jsx';

import { mainColor } from '../theme';
import Pencil from '../icons/Pencil.jsx';

const neutralLightColor = compose(
  desaturate(0.3),
  lighten(0.4)
)(mainColor);

const InlineForm = styled.form`
  display: flex;
  width: 100%;
`;

const styleNoValue = ({ hasValue }) => {
  if (hasValue) return '';
  return css`
    font-variant: italics;
    color: ${neutralLightColor};
  `;
};

const StyledSpan = styled.span`
  padding: 4px 0;
  display: inline-block;
  ${styleNoValue};
`;

const formatUnit = period => {
  if (period.value > 1) return `${period.unit}s`;
  return period.unit;
};
const periodInfo = period => `${period.value} ${formatUnit(period)}`;

const AlignedStyledInput = styled(StyledInput)`
  margin-left: -4px;
  margin-right: 1em;
  width: 4em;
`;

const EditableWrapper = styled.span`
  padding-right: 1.2em;
  position: relative;
`;

const EditButton = styled(InlineButton)`
  position: absolute;
  top: 0.175em;
  right: 0;
`;

const CombinedForm = React.memo(
  observer(({ period, onChange, onCancel, options }) => {
    const [innerValue, setInnerValue] = useState(period.value);
    const [innerUnit, setInnerUnit] = useState(period.unit);

    const formRef = useRef(null);

    useEffect(() => {
      setInnerValue(period.value);
    }, [period.value]);

    useEffect(() => {
      setInnerUnit(period.unit);
    }, [period.unit]);

    const cancelOnEscape = e => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };

    const doneEditing = () => {
      onChange(innerValue, innerUnit);
    };

    const cancelOnEscapeSaveOnTab = e => {
      cancelOnEscape(e);
      if (e.keyCode === 9) {
        doneEditing();
      }
    };

    useOutsideCallback([formRef], doneEditing);

    return (
      <InlineForm ref={formRef} onSubmit={doneEditing}>
        <AlignedStyledInput
          autoFocus
          value={innerValue}
          onChange={e => setInnerValue(e.target.value)}
          onKeyUp={cancelOnEscape}
          step="1"
          min="0"
          type="number"
        />
        <StyledSelect
          value={innerUnit}
          onKeyDown={cancelOnEscapeSaveOnTab}
          onChange={e => setInnerUnit(e.target.value)}
        >
          {options.map(({ name, id }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </StyledSelect>
      </InlineForm>
    );
  })
);

export default React.memo(
  observer(({ period, options }) => {
    const [isActive, setActive] = useState(false);

    const doneEditing = (value, unit) => {
      setActive(false);

      // eslint-disable-next-line no-param-reassign
      period.value = value;
      // eslint-disable-next-line no-param-reassign
      period.unit = unit;
    };

    const hasValue = !!period;

    if (!isActive)
      return (
        <EditableWrapper>
          <StyledSpan
            hasValue={hasValue}
            tabIndex="0"
            onClick={() => setActive(true)}
            onFocus={() => setActive(true)}
          >
            {hasValue ? periodInfo(period) : 'no value'}
          </StyledSpan>
          <EditButton onClick={() => setActive(true)}>
            <Pencil />
          </EditButton>
        </EditableWrapper>
      );
    return (
      <CombinedForm
        period={period}
        onChange={doneEditing}
        onCancel={() => setActive(false)}
        options={options}
      />
    );
  })
);
