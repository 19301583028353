import styled from 'styled-components/macro';

export const Section = styled.div`
  border-radius: 2px;
  background-color: #efefef;
  padding: 1em;
  width: 265px;
  margin-bottom: auto;
  &:not(:last-child) {
    margin-bottom: 2em;
  }
`;

export const Label = styled.span`
  display: flex;
  font-size: 0.8em;
  flex: 0 1 auto;
  font-variant: small-caps;
  margin-bottom: 2px;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 1 auto;

  margin: 0;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;
