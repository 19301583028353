import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ellipsis } from 'polished';

import { mainColor } from '../theme';

export default styled(Link)`
  text-decoration: none;
  color: ${mainColor};
  ${ellipsis()};

  &:visited {
    color: ${mainColor};
  }
`;
