import styled from 'styled-components/macro';
import { placeholder, desaturate, lighten } from 'polished';

import { compose } from 'ramda';

import { color } from '../theme';
import SelectInner, { Creatable as CreatableInner } from './Select.jsx';

const neutralLightColor = compose(
  desaturate(0.3),
  lighten(0.4),
  color()
);

export const FormBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  padding: 15px;
  border-radius: 2px;
`;

export const FormLine = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 1 auto;

  margin-left: auto;
  margin-right: auto;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  margin-right: 10px;
  margin-left: 10px;
`;

const colorWithError = ({ error, warning }) => {
  if (error) return 'color: red';
  if (warning) return 'color: orange';
  return '';
};

const borderColor = props => {
  if (props.error) return 'red';
  if (props.warning) return 'orange';
  return neutralLightColor(props);
};
export const Input = styled.input`
  font-family: EmojiSymbols, system-ui;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 2px;
  height: 2.25em;
  line-height: 2.25em;
  ${colorWithError};
  ${props =>
    placeholder({
      color: neutralLightColor(props),
      opacity: 1,
      'font-weight': 'normal',
    })};
  padding-left: 10px;
  padding-right 10px;
  border: solid 1px ${borderColor};

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:disabled {
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
`;

export const Select = styled(SelectInner)`
  margin-top: 2px;
  width: 100%;

  &.Select.is-disabled > .Select-control {
    background-color: #f9f9f9;
    cursor: not-allowed;
  }
`;

export const Creatable = styled(CreatableInner)`
  margin-top: 2px;
  width: 100%;
`;

export const Label = styled.span`
  display: flex;
  flex: 0 1 auto;
  font-size: 0.8em;
  line-height: 1.2em;
  font-weight: 600;
  margin-bottom: 2px;
`;

export const Description = styled.div`
  ${colorWithError};
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  font-size: 0.8em;
  line-height: 1.2em;
  font-weight: 200;
  margin-bottom: 2px;
`;

export const NumberInput = styled(Input).attrs({ type: 'number' })`
  width: 5em;
`;

export const FullWidthInput = styled(Input)`
  width: 100%;
`;

export const AdditionalText = styled.span`
  font-size: 0.8em;
  font-style: italic;
`;

export const InlineInputs = styled.div`
  display: flex;
  width: 100%;
  & > :not(:first-child) {
    margin-left: 1em;
  }
`;
