import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { format, eachDayOfInterval } from 'date-fns';

import { withRouter } from 'react-router-dom';

import EditableField from '../components/EditableField.jsx';
import EditableText from '../components/EditableText.jsx';
import EditableDatePicker from '../components/EditableDate.jsx';
import SideMenu from '../components/SideMenu.jsx';
import { ActionButton, ButtonBar } from '../components/Button.jsx';
import DatePicker from '../components/DatePicker.jsx';
import { StyledInput } from '../components/FormStyles.jsx';
import { Section, Line, Label } from '../components/InfoBox.jsx';
import TextWithLineReturn from '../components/TextWithLineReturn.jsx';
import ConfirmationDialogButton from '../components/ConfirmationDialogButton.jsx';

import useStudy from '../hooks/useStudy';
import usePatient from '../hooks/usePatient';
import { useStore } from '../state/store';

import { parseStdDate, formatStdDate } from '../date-helpers';
import { neutralColor } from '../theme';

const VisitBlock = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  :nth-child(odd) {
    background-color: #f9f9f9;
  }

  padding: 1em;
  border-radius: 2px;
`;

const VisitTitle = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: bigger;
  display: flex;
  align-items: center;

  margin-bottom: 0.6em;
`;

const COLOR_MAP = {
  planned: '#5c5cae',
  'next to plan': '#d87c41',
  'to plan': '#c65ba6',
  waiting: 'grey',
  optional: '#278c9c',
};

const VisitPill = styled.span`
  color: white;
  background-color: ${props => COLOR_MAP[props.status]};
  padding: 3px;
  font-size: 0.75em;
  border-radius: 2px;
  margin-left: 1em;
  font-weight: 300;
  white-space: nowrap;
`;

const useVisitInfo = patientVisits => {
  let openToDate = patientVisits.current && patientVisits.current.visitDate;
  if (!openToDate) {
    openToDate = patientVisits.window ? patientVisits.window.middle : undefined;
  }
  let highlighted;
  if (patientVisits.window !== null) {
    const { start, end } = patientVisits.window;
    if (start && end) highlighted = eachDayOfInterval({ start, end });
  }

  return [openToDate, highlighted];
};

const VisitSubBlock = styled.div`
  width: 265px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const Checklist = styled.ul`
  margin: 0;
  padding-left: 1.2em;
  list-style-type: none;
`;

const ChecklistItem = styled.li`
  & label {
    position: relative;
    line-height: 1.3em;
    display: flex;
    align-items: flex-start;
    user-select: none;
  }

  & input[type='checkbox'] {
    visibility: hidden;
    position: absolute;
  }

  & label:after {
    content: '';
    position: absolute;
    width: 0.6em;
    height: 0.4em;
    background: transparent;
    top: 0.45em;
    left: -1em;
    border: 2px solid ${neutralColor};
    border-radius: 1px;
    border-top: none;
    border-right: none;

    transform: rotate(-50deg);
  }

  & input[type='checkbox']:checked + label {
    opacity: 0.7;
    text-decoration: line-through;
  }

  & input[type='checkbox']:checked + label:after {
    border-color: #444;
  }
`;

const Visit = observer(({ patientVisits, isNext }) => {
  const [openToDate, highlighted] = useVisitInfo(patientVisits);

  return (
    <VisitBlock>
      <VisitTitle>
        {patientVisits.visit.name}
        {isNext && <VisitPill status="next to plan">next to plan</VisitPill>}
        {patientVisits.optional && <VisitPill status="optional">optional</VisitPill>}
      </VisitTitle>

      <VisitSubBlock>
        <Label>Visit Date</Label>
        {patientVisits.status === 'planned' && (
          <EditableDatePicker
            disabled={!patientVisits.canEdit}
            value={patientVisits.current && patientVisits.current.visitDate}
            highlighted={highlighted}
            openToDate={openToDate}
            onChange={patientVisits.setDate}
          />
        )}
        {patientVisits.status === 'to plan' && (
          <StyledInput
            as={DatePicker}
            disabled={!patientVisits.canEdit}
            selected={patientVisits.current && patientVisits.current.visitDate}
            highlightDates={highlighted}
            openToDate={openToDate}
            onChange={patientVisits.setDate}
          />
        )}
        {patientVisits.status === 'waiting' && (
          <span>Depends on {patientVisits.previousVisitName}</span>
        )}
      </VisitSubBlock>
      {!patientVisits.optional && patientVisits.window && (
        <VisitSubBlock>
          <Label>Window</Label>
          <span>{format(patientVisits.window.start, 'd.M.yyyy')}</span>
          {' – '}
          <span>{format(patientVisits.window.end, 'd.M.yyyy')}</span>
        </VisitSubBlock>
      )}
      {patientVisits.visit.comment && (
        <VisitSubBlock>
          <Label>Info</Label>
          <TextWithLineReturn>{patientVisits.visit.comment}</TextWithLineReturn>
        </VisitSubBlock>
      )}
      {!!patientVisits.visit.checklist.all.length && (
        <VisitSubBlock>
          <Label>Checklist</Label>
          <Checklist>
            {patientVisits.visit.checklist.all.map(c => (
              <ChecklistItem key={c.id}>
                <input
                  checked={patientVisits.info ? patientVisits.info.checklist.has(c.id) : false}
                  id={c.id}
                  onChange={() => {
                    patientVisits.toggleChecklist(c.id);
                  }}
                  type="checkbox"
                />
                <label htmlFor={c.id}>{c.content}</label>
              </ChecklistItem>
            ))}
          </Checklist>
        </VisitSubBlock>
      )}
      <VisitSubBlock>
        <Label>Comments</Label>
        <EditableText
          multiline
          value={patientVisits.info && patientVisits.info.comment}
          onChange={newValue => patientVisits.setComment(newValue)}
        />
      </VisitSubBlock>
    </VisitBlock>
  );
});

const PatientInfo = observer(({ patient, removePatient, study }) => (
  <Section>
    <Line>
      <Label>Name</Label>
      <EditableField item={patient} field="name" />
    </Line>
    <Line>
      <Label>Email</Label>
      <EditableField item={patient} field="email" />
    </Line>
    <Line>
      <Label>Phone</Label>
      <EditableField item={patient} field="phone" />
    </Line>
    <Line>
      <Label>Patient ID</Label>
      <EditableField item={patient} field="patient_id" />
    </Line>
    <Line>
      <Label>Birthdate</Label>
      <EditableDatePicker
        value={patient.birthdate}
        onChange={date => {
          // eslint-disable-next-line no-param-reassign
          patient.birthdate = date;
        }}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </Line>
    <Line>
      <Label>Comments</Label>
      <EditableField item={patient} field="comment" multiline />
    </Line>
    {study.additional_fields.all.map(field => {
      let value = patient.additional_fields.get(field.id);
      if (field.type === 'date' && value) {
        value = parseStdDate(value);
      }

      return (
        <Line key={field.id}>
          <Label>{field.name}</Label>
          {field.type === 'date' && (
            <EditableDatePicker
              value={value}
              onChange={date => patient.editField(field.id, formatStdDate(date))}
            />
          )}
          {field.type === 'text' && (
            <EditableText
              value={value}
              onChange={newValue => patient.editField(field.id, newValue)}
            />
          )}
        </Line>
      );
    })}

    <Line>
      <Label>Action</Label>
      <ButtonBar>
        {!patient.dropped && (
          <ConfirmationDialogButton onAccept={patient.dropOut} text="Drop Out">
            <p>
              Are you sure <b>{patient.name}</b> dropped out of the study.
            </p>
          </ConfirmationDialogButton>
        )}
        {patient.dropped && <ActionButton onClick={patient.joinBack}>Join Back</ActionButton>}

        <ConfirmationDialogButton onAccept={removePatient} text="Delete Patient">
          <p>
            Are you sure you want to delete the patient <b>{patient.name}</b>?
          </p>
          <p>You will not be able to restore its data.</p>
        </ConfirmationDialogButton>
      </ButtonBar>
    </Line>
  </Section>
));

const ConsentFormsMenu = observer(({ study, patient }) => {
  const store = useStore();

  const toggle = useCallback(() => {
    store.consentIsOpen = !store.consentIsOpen;
  }, [store]);

  return (
    <SideMenu isOpen={store.consentIsOpen} onClick={toggle} title="Consent Form">
      {study.consent_forms.all.map(consentForm => (
        <Line key={consentForm.id}>
          <Label>{consentForm.content}</Label>
          <EditableDatePicker
            value={parseStdDate(patient.signed_consent_forms.get(consentForm.id))}
            onChange={date => patient.signForm(consentForm.id, formatStdDate(date))}
            noValueTxt="not signed"
          />
        </Line>
      ))}
    </SideMenu>
  );
});

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VisitSection = styled.div`
  margin-right: 1em;
  flex: 1 1 0px;
  position: relative;
`;

const DroppedInfo = styled.div`
  font-size: 2em;
  background-color: #f3020236;
  color: #cf0000;

  border-radius: 2px;
  padding: 2em;

  text-align: center;

  margin-bottom: 1em;
`;

const DroppedDetails = styled.div`
  font-size: 1.5em;
  background-color: #efefef;

  border-radius: 2px;
  padding: 1.5em;

  text-align: center;

  margin-bottom: 1em;
`;

const DroppedOverlay = styled.div`
  position: absolute;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: white;
  opacity: 0.7;

  z-index: 5;
`;

export default withRouter(
  observer(({ match, history }) => {
    const study = useStudy(match);
    const patient = usePatient(match, study);

    if (!patient) return null;
    const removePatient = () => {
      patient.delete_();
      history.push(`/studies/${study.id}/`);
    };

    return (
      <div>
        <h3>{patient.name}</h3>
        {patient.dropped && (
          <>
            <DroppedInfo>This patient dropped from the study</DroppedInfo>
            <DroppedDetails>
              <EditableField
                multiline
                item={patient}
                field="dropped_info"
                noValueTxt="Remarks related to the patient dropping out"
              />
            </DroppedDetails>
          </>
        )}
        <Columns>
          <VisitSection>
            {patient.dropped && <DroppedOverlay />}
            {patient.visitsHistory.map(patientVisits => (
              <Visit
                key={patientVisits.visit.id}
                patientVisits={patientVisits}
                isNext={
                  !!patient.nextUnplanned &&
                  patientVisits.visit.id === patient.nextUnplanned.visit.id
                }
              />
            ))}
          </VisitSection>
          <div>
            <PatientInfo study={study} patient={patient} removePatient={removePatient} />
            {!!study.consent_forms.all.length && (
              <ConsentFormsMenu patient={patient} study={study} />
            )}
          </div>
        </Columns>
      </div>
    );
  })
);
