import isUndefined from 'lodash/isUndefined';
import fromPairs from 'lodash/fromPairs';

export class Field {
  constructor({ update, extract, create, val, deep = false } = {}) {
    this.updateFcn = update;

    this.extractFcn = extract;
    this.createFcn = create;
    this.defaultsVal = val;

    this.deep = deep;

    this.extract = this.extract.bind(this);
    this.update = this.update.bind(this);
  }

  create(fromVal = this.defaultsVal, modelContext) {
    if (this.createFcn) return this.createFcn(fromVal, modelContext);
    return fromVal;
  }

  extract(value) {
    if (this.extractFcn) return this.extractFcn(value);
    return value;
  }

  update(newVal, previousVal) {
    if (this.updateFcn) return this.updateFcn(newVal, previousVal);
    return newVal;
  }
}

export class FieldMapper {
  // With a field mapper you can maintain a complex object structure from
  // a json structure
  // It behaves both as a serializer / deserializer from JSON, as well
  // a definitions on how to update the fields (from JSON as well).
  constructor(fieldMapper) {
    this.fieldMapper = fieldMapper;

    this.new = this.new.bind(this);
    this.extract = this.extract.bind(this);
    this.update = this.update.bind(this);
  }

  fields(withDeep = true) {
    // list all the fields (or only the fields not defined as deep) defined in
    // this mapper
    const fields = Object.keys(this.fieldMapper);
    if (withDeep) return fields;
    return fields.filter(field => !this.fieldMapper[field].deep);
  }

  viewDefinition(source) {
    // This allows us to have the views fields defined from the fieldMapper.
    // Fields can be accessed and updated going threw the field mapper
    // functions.

    let view = {};
    const fieldMapper = this;
    this.fields().forEach(field => {
      const props = {
        enumerable: true,
        // eslint-disable-next-line object-shorthand, func-names
        get: function() {
          return source[field];
        },
      };

      if (field !== 'id') {
        // eslint-disable-next-line object-shorthand, func-names
        props.set = function(value) {
          fieldMapper.update(source, { [field]: value });
        };
      }

      view = Object.defineProperty(view, field, props);
    });

    return view;
  }

  new(data = {}, parent) {
    // Deserialise a JSON object into the complex model defined by the mapper
    const outData = {};
    Object.keys(this.fieldMapper).forEach(field => {
      outData[field] = this.fieldMapper[field].create(data[field], {
        parent,
        instanceId: data.id,
        sourceField: field,
      });
    });

    return outData;
  }

  extract(data, withDeepFields = false) {
    // Serializes the data as a set of simple JSON fields (that should be
    // easily imported back into the field mapper).

    const value = fromPairs(
      this.fields(withDeepFields).map(field => [
        field,
        this.fieldMapper[field].extract(data[field]),
      ])
    );
    return { key: data.id, value };
  }

  update(data, newData, silent) {
    // Updates the fields from a new set of data, JSON as well.
    // The silent option can be passed to complex fields.

    this.fields().forEach(field => {
      if (field === 'id') return;

      if (!isUndefined(newData[field])) {
        // eslint-disable-next-line no-param-reassign
        data[field] = this.fieldMapper[field].update(newData[field], data[field], silent);
      }
    });
  }
}
