import styled from 'styled-components/macro';
import { HEADER_AND_FOOTER_SIZE } from '../layout/constants';

export default styled.div`
  width: 100%;
  max-width: 1150px;
  min-height: calc(100vh - ${HEADER_AND_FOOTER_SIZE});
  flex: 0 1 auto;
  margin: auto;

  & > * {
    margin: auto;
  }
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;
