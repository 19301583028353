import styled from 'styled-components/macro';
import { ellipsis } from 'polished';

import { NavLink } from 'react-router-dom';
import { darken } from 'polished';

export default styled(NavLink)`
  flex: 0 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;

  text-decoration: none;

  cursor: pointer;
  color: ${darken(0.1, 'white')};
  fill: ${darken(0.1, 'white')};

  &.active {
    cursor: inherit;
    color: inherit;
    fill: inherit;
  }

  user-select: none;
  white-space: nowrap;

  :not(:last-child) {
    padding-right: 20px;
  }

  ${ellipsis()}
`;
