/* global window */

import { useEffect } from 'react';
import faker from 'faker/locale/de_CH';
import { addDays, addMonths, subDays, eachDayOfInterval, isAfter } from 'date-fns';

export default study => {
  useEffect(() => {
    window.fillName = (n = 1) => {
      [...Array(n).keys()].forEach(() =>
        study.newPatient({
          patient_id: faker.random.alphaNumeric(10),
          name: faker.name.findName(),
          email: faker.internet.email(),
          phone: faker.phone.phoneNumber(),
        })
      );
    };

    window.fillBasicStructure = () => {
      if (study.visits.all.length) return;
      study.visits.insert({
        name: 'First Visit',
      });

      const first = study.visits.all[0];

      study.visits.insert({
        name: 'Second Visit',
        after_visit: first.id,
        after_delay: { value: 3, unit: 'month' },
      });

      study.visits.insert({
        name: 'Third Visit',
        after_visit: first.id,
        after_delay: { value: 6, unit: 'month' },
      });

      study.visits.insert({
        name: 'Last Visit',
        after_visit: first.id,
        after_delay: { value: 12, unit: 'month' },
      });
    };

    window.fillVisits = (percentDone = 0.5) => {
      const daysDuration = study.visits.all.slice(-1)[0].positionHelper;
      const now = new Date();
      const nextPlan = addMonths(now, 2);

      const allDates = eachDayOfInterval({
        start: subDays(now, Math.ceil(daysDuration * percentDone)),
        end: addDays(now, Math.ceil(daysDuration * (1 - percentDone))),
      });

      study.patients.all.forEach(patient => {
        if (patient.history.length) return;

        while (true) {
          const unplanned = patient.nextUnplanned;
          if (!unplanned) return;
          if (unplanned.window && isAfter(unplanned.window.start, nextPlan)) return;

          let dates = allDates;
          if (unplanned.window) {
            dates = eachDayOfInterval(unplanned.window);
          }

          unplanned.setDate(dates[faker.random.number(dates.length - 1)]);
        }
      });
    };

    return () => {
      window.fillName = null;
      window.fillVisits = null;
      window.fillBasicStructure = null;
    };
  }, [study]);
};
