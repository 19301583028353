import { action, computed, decorate } from 'mobx';
import uuid from 'uuid/v4';
import sortBy from 'lodash/sortBy';
import { map, sorted, max } from 'itertools';

import {
  allStudies,
  newStudy,
  updateStudy,
  getStudy,
  getPatient,
  deleteStudy,
  studyTeam,
  addTeamMember,
  removeTeamMember,
} from '../service/studies';

import { Field, FieldMapper } from '../storelib/fieldMapper';
import BaseStore from '../storelib/store';
import FieldStore, { BaseFieldStore } from '../storelib/storeField';

import Patients from './patients';
import Visits from './visits';

const ConsentForm = new FieldMapper({
  id: new Field({ create: id => id || uuid() }),
  content: new Field({ val: '' }),
  order: new Field({ val: 0 }),
});

class RawConsentForms extends BaseFieldStore {
  fieldMapper = ConsentForm;

  get all() {
    return sorted(map(this.raw.keys(), key => this.get(key)), c => c.order);
  }

  get maxOrder() {
    return (max(this.all, v => v.order) || { order: 0 }).order;
  }
}
const ConsentForms = decorate(RawConsentForms, { maxOrder: computed, all: computed });

const AdditionalField = new FieldMapper({
  id: new Field({ create: id => id || uuid() }),
  name: new Field({ val: 'New Field' }),
  type: new Field({ val: 'text' }),
  order: new Field({ val: 0 }),
});

class RawAdditionalField extends BaseFieldStore {
  fieldMapper = AdditionalField;

  get all() {
    return sorted(map(this.raw.keys(), key => this.get(key)), c => c.order);
  }

  get maxOrder() {
    return (max(this.all, v => v.order) || { order: 0 }).order;
  }
}
const AdditionalFields = decorate(RawAdditionalField, { maxOrder: computed, all: computed });

const StudyModel = new FieldMapper({
  id: new Field({ defaults: id => id || uuid() }),
  name: new Field({ val: '' }),
  sponsor: new Field({ val: '' }),
  consent_forms: new FieldStore(config => new ConsentForms(config)),
  patients: new FieldStore(parentStudyId => new Patients(parentStudyId)),
  visits: new FieldStore(studyConfig => new Visits(studyConfig)),
  additional_fields: new FieldStore(studyConfig => new AdditionalFields(studyConfig)),
});

class Studies extends BaseStore {
  fieldMapper = StudyModel;

  changeOptions = { delay: 100 };

  constructor(backend) {
    super();
    this.backend = backend;
    this.updating = Promise.resolve();

    this.query = this.query.bind(this);
  }

  query(run, authed) {
    return this.updating
      .then(() => this.backend.query(run, authed))
      .catch(() => this.backend.query(run, authed));
  }

  get list() {
    return [...this.raw.values()];
  }

  init() {
    return this.query(() => allStudies().then(this.refresh));
  }

  itemView() {
    return [
      {
        studies: this,
        backend: this.backend,

        team: [],

        get patientsByUnplanned() {
          return sortBy(this.patients.all, patient => {
            const unplannedOrder = patient.nextUnplanned ? 0 : 1;
            const dropOutOrder = patient.dropped ? 1 : 0;
            const unplannedTime =
              patient.nextUnplanned &&
              patient.nextUnplanned.window &&
              patient.nextUnplanned.window.middle.getTime();

            return [dropOutOrder, unplannedOrder, unplannedTime || 0, patient.name];
          });
        },

        newPatient(patient) {
          return this.studies.backend.query(() =>
            updateStudy(this.id, { patients: [patient] }).then(this.studies.upsert)
          );
        },
        refreshPatient(patientId) {
          return this.studies.query(() =>
            getPatient(this.id, patientId).then(patient => this.patients.upsert(patient, true))
          );
        },

        refreshTeam() {
          return this.studies.query(() =>
            studyTeam(this.id).then(teamInfo => {
              this.team = Object.keys(teamInfo);
            })
          );
        },

        addTeamMember(email) {
          return this.studies.query(() =>
            addTeamMember(this.id, email).then(teamInfo => {
              this.team = Object.keys(teamInfo);
            })
          );
        },

        removeTeamMember(email) {
          return this.studies.query(() =>
            removeTeamMember(this.id, email).then(teamInfo => {
              this.team = Object.keys(teamInfo);
            })
          );
        },

        newConsentForm(content) {
          this.consent_forms.insert({
            content,
            order: this.consent_forms.maxOrder + 1,
          });
        },

        newAdditionalField(name) {
          this.additional_fields.insert({
            name,
            order: this.additional_fields.maxOrder + 1,
          });
        },
      },
      {
        newPatient: action.bound,
        refreshPatient: action.bound,
        newConsentForm: action.bound,
        newAdditionalField: action.bound,
        patientsByUnplanned: computed,
      },
    ];
  }

  refreshAll() {
    this.query(() => allStudies().then(this.refresh));
  }

  refreshStudy(studyId) {
    this.query(() => getStudy(studyId).then(study => this.upsert(study, true)));
  }

  newStudy(studyInfo) {
    return this.query(() =>
      newStudy(studyInfo).then(study => {
        this.insert(study, true);
      })
    );
  }

  onChange(key, value) {
    if (!value) {
      this.updating = this.updating.then(() => this.backend.query(() => deleteStudy(key)));
      return;
    }

    this.updating = this.updating.then(() =>
      this.backend
        .query(() => updateStudy(key, value))
        .then(study => {
          this.refresh([study]);
        })
    );
  }
}

decorate(Studies, {
  init: action.bound,
  add: action.bound,
  refreshAll: action.bound,
  refreshStudy: action.bound,
});

const makeStudies = backend => new Studies(backend);

export default makeStudies;
