import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import { sorted } from 'itertools';

import useStudy from '../hooks/useStudy';
import useToggle from '../quarolib/hooks/useToggle';

import { VisitModel } from '../state/visits';
import SideMenu from '../components/SideMenu.jsx';
import EditableField from '../components/EditableField.jsx';
import EditablePeriod from '../components/EditablePeriod.jsx';
import EditableSelect from '../components/EditableSelect.jsx';
import EditableText from '../components/EditableText.jsx';
import { ActionButton, ButtonBar, TextButton } from '../components/Button.jsx';
import { StyledInput, StyledSelect } from '../components/FormStyles.jsx';
import { FormLine, InlineInputs } from '../components/form-fields.jsx';
import { Line, Label } from '../components/InfoBox.jsx';
import ConfirmationDialogButton from '../components/ConfirmationDialogButton.jsx';

import Remove from '../icons/Remove.jsx';
import urlTo from '../urls';

import {
  Columns,
  MainSection,
  MainBlock,
  BlockTitle,
  SubBlock,
} from '../components/PageLayout.jsx';

const ReactiveInput = observer(({ item, field, ...props }) => (
  <StyledInput
    {...props}
    value={item && item[field]}
    onChange={e => {
      // eslint-disable-next-line no-param-reassign
      item[field] = e.target.value;
    }}
  />
));

const ReactiveSelect = observer(({ item, field, options, withEmpty, ...props }) => (
  <StyledSelect
    {...props}
    value={item && item[field]}
    onChange={e => {
      // eslint-disable-next-line no-param-reassign
      item[field] = e.target.value;
    }}
  >
    {withEmpty && <option value="">---</option>}
    {options.map(({ name, id }) => (
      <option key={id} value={id}>
        {name}
      </option>
    ))}
  </StyledSelect>
));

const VisitTypeSelect = observer(({ item, ...props }) => (
  <StyledSelect
    {...props}
    value={item && item.optional ? 'optional' : 'compulsory'}
    onChange={e => {
      // eslint-disable-next-line no-param-reassign
      item.optional = e.target.value === 'optional';
    }}
  >
    <option value="compulsory">Standard</option>
    <option value="optional">Optional</option>
  </StyledSelect>
));

const PERIODS = [
  { id: 'day', name: 'Days' },
  { id: 'week', name: 'Weeks' },
  { id: 'month', name: 'Months' },
  { id: 'year', name: 'Years' },
];

const NumberReactiveInput = styled(ReactiveInput)`
  width: 5em;
`;
const PeriodSelect = observer(({ id, period }) => (
  <InlineInputs>
    <NumberReactiveInput id={id} item={period} field="value" type="number" step="1" min="0" />
    <ReactiveSelect item={period} field="unit" options={PERIODS} />
  </InlineInputs>
));

const ButtonFormLine = styled(FormLine)`
  align-items: flex-end;
  justify-content: flex-end;
`;

const NewVisit = observer(({ visits }) => {
  const [newVisit, setNewVisit] = useState(observable(VisitModel.new()));

  useEffect(() => {
    if (visits.count === 0) return;
    newVisit.after_visit = visits.all[0].id;
  }, [newVisit]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        visits.insert(VisitModel.extract(newVisit).value);
        setNewVisit(observable(VisitModel.new()));
      }}
    >
      <Line>
        <Label as="label" htmlFor="visit-name">
          Name
        </Label>
        <ReactiveInput id="visit-name" item={newVisit} field="name" />
      </Line>

      <Line>
        <Label as="label" htmlFor="visit-type-select">
          Type of visit
        </Label>
        <VisitTypeSelect id="visit-type-select" item={newVisit} />
      </Line>

      {!newVisit.optional && (
        <Line>
          <Label as="label" htmlFor="visit-after-visit">
            After Visit
          </Label>
          <ReactiveSelect
            id="visit-after-visit"
            withEmpty
            item={newVisit}
            field="after_visit"
            options={visits.all}
          />
        </Line>
      )}
      {!newVisit.optional && newVisit.after_visit && (
        <Line>
          <Label as="label" htmlFor="visit-after-delay">
            Delay
          </Label>
          <PeriodSelect id="visit-after-delay" period={newVisit.after_delay} />
        </Line>
      )}

      {!newVisit.optional && newVisit.after_visit && (
        <Line>
          <Label as="label" htmlFor="visit-window">
            Window
          </Label>
          <PeriodSelect id="visit-window" period={newVisit.window} />
        </Line>
      )}

      <Line>
        <Label as="label" htmlFor="visit-comments">
          Comments
        </Label>
        <ReactiveInput as="textarea" id="visit-comments" item={newVisit} field="comment" />
      </Line>

      <ButtonFormLine>
        <ButtonBar>
          <ActionButton type="submit">Add Visit</ActionButton>
        </ButtonBar>
      </ButtonFormLine>
    </form>
  );
});

const NewVisitMenu = observer(({ study }) => {
  const [isOpen, , , toggle] = useToggle(false);

  return (
    <SideMenu isOpen={isOpen} onClick={toggle} title="Add Visit">
      <NewVisit visits={study.visits} />
    </SideMenu>
  );
});

const StyledFormList = styled.ul`
  padding-left: 1em;
  margin: 0;
  list-style: none;

  li {
    position: relative;
  }

  & li::before {
    position: absolute;
    left: -0.8em;
    top: 0.3em;
    content: '-';
    color: #444;
  }
`;
const ConsentForms = observer(({ study }) => {
  const formVersions = sorted(study.consent_forms.all, v => v.order);
  return (
    <StyledFormList>
      {formVersions.map(form => (
        <li key={form.id}>
          <EditableText
            value={form.content}
            onChange={newVal => {
              if (!newVal) {
                form.delete_();
                return;
              }
              form.content = newVal;
            }}
          />
        </li>
      ))}
      <li>
        <EditableText
          value=""
          noValueTxt="new version"
          onChange={v => {
            if (v) study.newConsentForm(v);
          }}
        />
      </li>
    </StyledFormList>
  );
});

const ConfigurationMenu = observer(({ study, deleteStudy }) => {
  const [isOpen, , , toggle] = useToggle(false);

  return (
    <SideMenu isOpen={isOpen} onClick={toggle} title="Configuration">
      <Line>
        <Label>Name</Label>
        <EditableField item={study} field="name" />
      </Line>
      <Line>
        <Label>Sponsor</Label>
        <EditableField item={study} field="sponsor" />
      </Line>
      <Line>
        <Label>Consent Form Versions</Label>
        <ConsentForms study={study} />
      </Line>
      <Line>
        <Label>Actions</Label>
        <ButtonBar>
          <ConfirmationDialogButton onAccept={deleteStudy} text="Delete Study">
            <p>
              Are you sure you want to delete the study <b>{study.name}</b>?
            </p>
            <p>You will not be able to restore its data.</p>
          </ConfirmationDialogButton>
        </ButtonBar>
      </Line>
    </SideMenu>
  );
});

const NewFieldForm = styled.form`
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;

  & > :not(:first-child) {
    margin-top: 5px;
  }
`;

const FIELD_TYPES = [{ id: 'text', name: 'Free Text' }, { id: 'date', name: 'Date' }];

const FieldConfig = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  width: 100%;
`;

const AdditionalPatientFieldMenu = observer(({ study }) => {
  const [isOpen, , , toggle] = useToggle(false);
  const [newFieldName, setNewFieldName] = useState('');

  return (
    <SideMenu isOpen={isOpen} onClick={toggle} title="Custom Patient Fields">
      {study.additional_fields.map(field => (
        <Line key={field.id}>
          <Label>
            <EditableField item={field} field="name" />
          </Label>
          <FieldConfig>
            <EditableSelect
              value={field.type}
              options={FIELD_TYPES}
              onChange={newValue => {
                // eslint-disable-next-line no-param-reassign
                field.type = newValue;
              }}
            />
            <ConfirmationDialogButton
              ButtonComponent={TextButton}
              text={<Remove width="1.4em" />}
              confirmText="Remove"
              onAccept={() => field.delete_()}
            >
              <p>
                Are you sure you want to delete the cutom field <b>{field.name}</b>?
              </p>
            </ConfirmationDialogButton>
          </FieldConfig>
        </Line>
      ))}

      <NewFieldForm
        onSubmit={e => {
          e.preventDefault();
          if (!newFieldName) return;
          study.newAdditionalField(newFieldName);
          setNewFieldName('');
        }}
      >
        <StyledInput
          placeholder="New Field Title"
          value={newFieldName}
          onChange={e => setNewFieldName(e.target.value)}
        />
        <ButtonBar>
          <ActionButton type="submit">Add Field</ActionButton>
        </ButtonBar>
      </NewFieldForm>
    </SideMenu>
  );
});

const StyledChecklist = styled.ul`
  font-size: 0.8em;
  padding-left: 1em;
  margin: 0;
  list-style: none;

  li {
    position: relative;
  }

  & li::before {
    position: absolute;
    left: -0.8em;
    top: 0.3em;
    content: '-';
    color: #444;
  }
`;
const Checklist = observer(({ visit }) => {
  const checklist = sorted(visit.checklist.all, v => v.order);
  return (
    <StyledChecklist>
      {checklist.map(item => (
        <li key={item.id}>
          <EditableText
            value={item.content}
            onChange={newVal => {
              if (!newVal) {
                item.delete_();
                return;
              }
              item.content = newVal;
            }}
          />
        </li>
      ))}
      <li>
        <EditableText
          value=""
          noValueTxt="new item"
          onChange={v => {
            if (v) visit.newChecklistItem(v);
          }}
        />
      </li>
    </StyledChecklist>
  );
});

const Visits = ({ match, history }) => {
  const study = useStudy(match);

  const deleteStudy = useCallback(() => {
    study.delete_();
    history.push(urlTo('studies'));
  }, [study, history]);

  return (
    <Columns>
      <MainSection>
        {study.visits.map(visit => (
          <MainBlock key={visit.id}>
            <BlockTitle>
              <EditableField item={visit} field="name" />
            </BlockTitle>
            {visit.optional && (
              <SubBlock>
                <Label>Visit Type</Label>
                {visit.optional ? 'Optional' : 'Standard'}
              </SubBlock>
            )}
            <SubBlock>
              <Label>After visit</Label>
              {!visit.optional && visit.after_visit
                ? (study.visits.get(visit.after_visit) || {}).name
                : 'Unconstrained planning'}
            </SubBlock>
            {!visit.optional && visit.after_visit && (
              <SubBlock>
                <Label>Delay</Label>
                <EditablePeriod period={visit.after_delay} withEmpty options={PERIODS} />
              </SubBlock>
            )}
            {!visit.optional && visit.after_visit && (
              <SubBlock>
                <Label>Window</Label>
                <EditablePeriod period={visit.window} withEmpty options={PERIODS} />
              </SubBlock>
            )}
            <SubBlock>
              <Label>Info</Label>
              <EditableField item={visit} field="comment" multiline />
            </SubBlock>
            <SubBlock>
              <Label>Checklist</Label>
              <Checklist visit={visit} />
            </SubBlock>
            {!visit.hasDependants && (
              <SubBlock>
                <Label>Delete visit</Label>
                <ConfirmationDialogButton onAccept={() => visit.delete_()} text="Delete Visit">
                  <p>
                    Are you sure you want to delete the visit <b>{visit.name}</b>?
                  </p>
                </ConfirmationDialogButton>
              </SubBlock>
            )}
          </MainBlock>
        ))}
      </MainSection>
      <div>
        <ConfigurationMenu study={study} deleteStudy={deleteStudy} />
        <NewVisitMenu study={study} />
        <AdditionalPatientFieldMenu study={study} />
      </div>
    </Columns>
  );
};

export default withRouter(observer(Visits));
