import service from './service';

export const allStudies = () => service.get('studies/').then(response => response.data);
export const getStudy = studyId =>
  service.get(`studies/${studyId}`).then(response => response.data);

export const getPatient = (studyId, patientId) =>
  service.get(`studies/${studyId}/patients/${patientId}`).then(response => response.data);

export const updateStudy = (studyId, object) =>
  service.put(`studies/${studyId}`, object).then(response => response.data);

export const deleteStudy = studyId =>
  service.delete(`studies/${studyId}`).then(response => response.data);

export const newStudy = object => {
  const { name, createPatients, ...info } = object;
  return service
    .post(`studies/`, {
      name,
      ...info,
      create_patients: createPatients,
    })
    .then(response => response.data);
};

export const studyTeam = studyId =>
  service.get(`studies/${studyId}/permissions`).then(response => response.data);

export const addTeamMember = (studyId, memberEmail) =>
  service.post(`studies/${studyId}/invite`, { email: memberEmail }).then(response => response.data);

export const removeTeamMember = (studyId, memberEmail) =>
  service
    .post(`studies/${studyId}/permissions`, { [memberEmail]: 'remove' })
    .then(response => response.data);
