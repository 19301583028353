import React from 'react';
import styled from 'styled-components/macro';

import { color } from '../theme';

const FootInfo = styled.span`
  margin-left: auto;
  font-size: small;
  color: ${color(1)};
`;

export default () => <FootInfo>Instoa</FootInfo>;
