import { useEffect, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';

import { useStore } from '../state/store';

export default match => {
  const store = useStore();
  const study = useRef(null);

  const missingId = !match || !match.params || !match.params.studyId;
  let studyId = null;
  if (!missingId) {
    studyId = match.params.studyId;
  }

  useEffect(() => {
    if (missingId) return;
    store.studies.refreshStudy(studyId);
  }, [missingId, studyId]);

  useObserver(() => {
    if (missingId) return;
    study.current = store.studies.get(studyId);
  }, [missingId, studyId]);

  return study.current;
};
