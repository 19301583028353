import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import Header from './Header.jsx';
import Footer from './Footer.jsx';

import useToggle from '../quarolib/hooks/useToggle';

import { HEADER_SIZE, MENU_SIZE } from './constants';

const Main = styled.div`
  min-width: 100%;
  min-height: 100vh;

  margin: 0;
  padding-top: ${HEADER_SIZE};

  display: flex;
  flex-direction: column;

  transition: all 300ms ease-out;

  ${props => props.menuOpen && `transform: translateX(${MENU_SIZE});`};

  @media print {
    display: block;
    min-height: 100%;
    padding-top: 0px;
  }
`;

export default ({ headerMenu, sideMenu, footer, location, children }) => {
  const [isOpen, , closeMenu, toggleMenu] = useToggle(false);
  useEffect(() => {
    if (location) closeMenu();
  }, [location]);

  return (
    <>
      <Header
        menuOpen={isOpen}
        closeMenu={closeMenu}
        toggleMenu={toggleMenu}
        sideMenu={sideMenu}
        headerMenu={headerMenu}
      />
      <Main menuOpen={isOpen}>
        {children}
        <Footer>{footer}</Footer>
      </Main>
    </>
  );
};
