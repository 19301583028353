import React, { useCallback, useState } from 'react';
import styled from 'styled-components/macro';

import { observer } from 'mobx-react-lite';
import { withRouter, Link } from 'react-router-dom';

import {
  format,
  isBefore,
  addMonths,
  addWeeks,
  isSameDay,
  isSameWeek,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { ellipsis } from 'polished';
import { Formik, Form } from 'formik';

import sortBy from 'lodash/sortBy';

import {
  CancelButton,
  ActionButton,
  ButtonBar,
  TextButton,
  Button,
} from '../components/Button.jsx';
import EditableField from '../components/EditableField.jsx';
import SideMenu from '../components/SideMenu.jsx';
import StyledLink from '../components/StyledLink.jsx';
import { StyledInput, StyledSelect } from '../components/FormStyles.jsx';

import { Section, Line, Label } from '../components/InfoBox.jsx';
import ParticipantForm from '../parts/ParticipantForm.jsx';

import urlTo from '../urls';
import { formatDayFromNow } from '../date-helpers';

import { useStore } from '../state/store';
import useToggle from '../quarolib/hooks/useToggle';
import useStudy from '../hooks/useStudy';
import useStudyHistory from '../hooks/useStudyHistory';
import useFakeStudy from '../hooks/useFakeStudy';

import TeamMenu from '../parts/TeamMenu.jsx';

const Dates = styled.div`
  display: inline-flex;
  flex-direction: column;
  font-size: 0.7em;

  & > :first-child {
    font-size: 1.2em;
  }
`;

const Input = styled(StyledInput)`
  max-width: 10em;
`;

const Select = styled(StyledSelect)`
  max-width: 19em;
`;

const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.span`
  ${ellipsis()}
`;

const DateFormat = ({ date }) => (
  <Dates>
    <Text>{formatDayFromNow(date)}</Text>
    <Text>{format(date, 'd.MM.yyyy')}</Text>
  </Dates>
);

const D = () => <span>–</span>;

const NextUnplanned = observer(({ patient }) => {
  if (!patient.nextUnplanned || patient.dropped)
    return (
      <>
        <D />
        <D />
      </>
    );

  return (
    <>
      <Text>{patient.nextUnplanned.visit.name}</Text>
      {patient.nextUnplanned.window ? (
        <DateFormat date={patient.nextUnplanned.window.middle} />
      ) : (
        <D />
      )}
    </>
  );
});

const LastPlanned = observer(({ patient }) => {
  if (!patient.nextAfterToday || patient.dropped)
    return (
      <>
        <D />
        <D />
      </>
    );

  return (
    <>
      <Text>{patient.nextAfterToday.visit.name}</Text>
      {patient.nextAfterToday ? (
        <DateFormat date={patient.nextAfterToday.current.visitDate} />
      ) : (
        <D />
      )}
    </>
  );
});

const ConsentFormVersion = observer(({ patient }) => {
  if (!patient.latestConsentForm || patient.dropped) return <D />;

  return <Text>{patient.latestConsentForm.content}</Text>;
});

const NoPatients = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2em;
  font-size: 1.5em;
  color: #bbb;
`;

const HeaderForm = styled.form`
  display: flex;
  flex-wrap: wrap;

  margin-top: 1em;
`;

const NewParticipant = ({ isSubmitting, cancel }) => (
  <HeaderForm as={Form}>
    <ParticipantForm />
    <ButtonBar style={{ width: '100%' }}>
      <CancelButton
        type="button"
        onClick={e => {
          e.preventDefault();
          cancel();
        }}
      >
        Cancel
      </CancelButton>
      <ActionButton disable={isSubmitting} type="submit">
        Save
      </ActionButton>
    </ButtonBar>
  </HeaderForm>
);

const PatientsSection = styled.div`
  margin-right: 30px;
  flex: 1 1 0px;
`;

const PatientWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;

  border-left: 3px solid transparent;

  padding: 10px;
  padding-left: 6px;

  ${props => props.warn && 'border-left-color: #d87c41;'};

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }

  & > * {
    :first-child {
      width: calc((100% - 25px) / 6.5 * 1.5);
    }

    &:not(:first-child) {
      margin-left: 5px;
      width: calc((100% - 25px) / 6.5);
    }
  }
`;

const DroppedOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;

  width: 80%;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  opacity: 0.8;
`;

const PatientName = styled.div`
  display: flex;
  flex-direction: column;
`;

const IDText = styled(Text)`
  margin-top: 3px;
  font-size: 0.6em;
  font-weight: 200;
`;

const Patient = React.memo(
  observer(({ study, patient }) => {
    const shouldWarn =
      !patient.dropped &&
      patient.nextUnplanned &&
      (!patient.nextUnplanned.window ||
        isBefore(patient.nextUnplanned.window.start, addMonths(startOfDay(new Date()), 2)));
    return (
      <PatientWrapper warn={shouldWarn}>
        <PatientName>
          <StyledLink to={urlTo('patient', { study, patient })}>{patient.name}</StyledLink>
          <IDText>{patient.patient_id}</IDText>
        </PatientName>
        {!patient.dropped && (
          <>
            <LastPlanned patient={patient} />
            <NextUnplanned patient={patient} />
            <ConsentFormVersion patient={patient} />
          </>
        )}
        {patient.dropped && <DroppedOverlay>Dropped</DroppedOverlay>}
      </PatientWrapper>
    );
  })
);

const PatientTitleWrapper = styled(PatientWrapper)`
  font-size: 0.8em;
  font-variant: small-caps;
  padding-bottom: 3px;

  user-select: none;
`;

const PatientTitle = () => (
  <PatientTitleWrapper>
    <Text>Patient</Text>
    <Text>Next Visit</Text>
    <Text />
    <Text>Next to plan</Text>
    <Text />
    <Text>Consent Form</Text>
  </PatientTitleWrapper>
);

const StudyInfo = observer(({ study }) => (
  <Section>
    <Line>
      <Label>Name</Label>
      <EditableField item={study} field="name" />
    </Line>
    <Line>
      <Label>Sponsor</Label>
      <EditableField item={study} field="sponsor" />
    </Line>
    <Line>
      <Label>Visits</Label>
      <span>{study.visits.count} visits planned</span>
    </Line>
    <Line>
      <Label>Patients</Label>
      <span>{study.patients.all.length} patients registered</span>
    </Line>
    <Line>
      <Label>Actions</Label>
      <ButtonBar>
        <Button as={Link} to={urlTo('visits', { study })}>
          Configure
        </Button>
      </ButtonBar>
    </Line>
  </Section>
));

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NewPatientMenu = observer(({ study }) => {
  const [isOpen, , close, toggle] = useToggle(false);

  const newParticipant = (values, actions) => {
    actions.setSubmitting(true);

    study.newPatient(values).then(() => {
      actions.setSubmitting(false);
      actions.resetForm();
    });
  };

  return (
    <SideMenu isOpen={isOpen} onClick={toggle} title="New Patient">
      <Formik
        initialValues={{
          name: '',
          phone: '',
          email: '',
          comment: '',
        }}
        onSubmit={newParticipant}
        render={props => <NewParticipant {...props} cancel={close} />}
      />
    </SideMenu>
  );
});

const ToLoadSection = styled.div`
  display: flex;
  justify-content: center;

  font-size: 1.3em;
  margin-top: 2em;
`;

const useFilter = () => {
  const store = useStore();

  const setFilter = useCallback(
    value => {
      store.filter = value;
    },
    [store]
  );
  return [store.filter, setFilter];
};

export default withRouter(
  observer(({ match }) => {
    const study = useStudy(match);
    const [toLoad, setToLoad] = useState(20);
    const [filterText, setFilterText] = useState('');

    const [typeFilter, setTypeFilter] = useFilter();
    const [, updateStudyHistory] = useStudyHistory();
    updateStudyHistory(study.id);

    useFakeStudy(study);

    if (!study) return <div />;

    const hasPatients = study.patientsByUnplanned.length !== 0;
    let patients = study.patientsByUnplanned;
    if (filterText) {
      const filter = filterText.toLowerCase();
      patients = patients.filter(patient => {
        const nameFound = patient.name.toLowerCase().indexOf(filter) !== -1;
        if (nameFound || !patient.patient_id) return nameFound;
        return patient.patient_id.toLowerCase().indexOf(filter) !== -1;
      });
    }

    const now = startOfDay(new Date());

    if (!['all', 'drop-outs'].includes(typeFilter)) {
      patients = patients.filter(patient => !patient.dropped);
    }

    if (typeFilter === 'drop-outs') {
      patients = patients.filter(patient => patient.dropped);
    }

    if (typeFilter === 'today') {
      patients = patients.filter(patient => {
        if (!patient.nextAfterToday) return false;
        return isSameDay(patient.nextAfterToday.current.visitDate, now);
      });
    }

    if (typeFilter === 'this-week') {
      const thisWeek = startOfWeek(now);
      patients = patients.filter(patient =>
        patient.visitsHistory.find(
          visit => visit.current && isSameWeek(visit.current.visitDate, now)
        )
      );
      patients = sortBy(patients, patient => {
        const nextVisit = patient.nextAfterDate(thisWeek);
        if (nextVisit) return -nextVisit.current.visitDate.getTime();
        return 0;
      });
    }

    if (typeFilter === 'next-week') {
      const nextWeek = startOfWeek(addWeeks(new Date(), 1));
      patients = patients.filter(patient =>
        patient.visitsHistory.find(
          visit => visit.current && isSameWeek(visit.current.visitDate, nextWeek)
        )
      );

      patients = sortBy(patients, patient => {
        const nextVisit = patient.nextAfterDate(nextWeek);
        if (nextVisit) return nextVisit.current.visitDate.getTime();
        return 0;
      });
    }

    if (typeFilter === 'need-appointment') {
      patients = patients.filter(
        patient =>
          patient.nextUnplanned &&
          (!patient.nextUnplanned.window ||
            isBefore(patient.nextUnplanned.window.start, addMonths(now, 2)))
      );
    }

    return (
      <>
        <Columns>
          <PatientsSection>
            <FilterBar>
              <Input
                placeholder="Filter by name or id"
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
              />
              <Select value={typeFilter} onChange={e => setTypeFilter(e.target.value)}>
                <option value="all">All patients</option>
                <option value="today">Patients planned today</option>
                <option value="this-week">Patients planned this week</option>
                <option value="next-week">Patients planned next week</option>
                <option value="need-appointment">Patients who need an appointment</option>
                <option value="drop-outs">Patients who dropped out</option>
              </Select>
            </FilterBar>
            <PatientTitle />
            {hasPatients && patients.length === 0 && (
              <NoPatients>No patient with this criteria</NoPatients>
            )}
            {patients.slice(0, toLoad).map(p => (
              <Patient key={p.id} patient={p} study={study} />
            ))}
            {toLoad < patients.length && (
              <ToLoadSection>
                <TextButton onClick={() => setToLoad(toLoad + 20)}>Load More...</TextButton>
              </ToLoadSection>
            )}
          </PatientsSection>
          <div>
            <StudyInfo study={study} />
            <NewPatientMenu study={study} />
            <TeamMenu study={study} />
          </div>
        </Columns>
      </>
    );
  })
);
