import querystring from 'querystring';

const URLS = {
  study: ({ study = { id: ':studyId' } }) => `/studies/${study.id}/`,
  patient: ({ study = { id: ':studyId' }, patient = { id: ':patientId' } }) =>
    `/studies/${study.id}/patients/${patient.id}/`,
  visits: ({ study = { id: ':studyId' } }) => `/studies/${study.id}/configure/`,
  login: ({ next } = {}) => `/login${next ? `/?${querystring.stringify({ next })}` : ''}`,
  studies: () => '/studies',
};

export default (path, object) => {
  if (!URLS[path]) throw new Error(`cannot find path ${path}`);
  return URLS[path](object || {});
};
