import { lighten, desaturate } from 'polished';
import { compose } from 'ramda';

// export const mainColor = 'rgb(140, 62, 128)';
export const mainColor = 'rgb(62, 140, 101)';
export const neutralLightColor = compose(
  desaturate(0.2),
  lighten(0.5)
)(mainColor);

export const neutralColor = compose(
  desaturate(0.3),
  lighten(0.4)
)(mainColor);

export default {
  palette: {
    primary: [mainColor, mainColor, mainColor, mainColor, mainColor],
  },
};

export const color = (type = null, shade = 0) => {
  /* eslint-disable no-param-reassign */
  if (typeof type === 'number' && Number.isFinite(type)) {
    shade = type;
    type = null;
  }
  /* eslint-enable no-param-reassign */
  return ({ color: inputColor, theme }) => {
    if (inputColor) return inputColor;
    if (!theme || !theme.palette) return 'grey';

    return theme.palette[type || theme.defaultColor || 'primary'][shade];
  };
};
