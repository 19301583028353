import { mainColor } from '../theme';

const header = 50;
const footer = 50;

export const HEADER_SIZE = `${header}px`;
export const FOOTER_SIZE = `${footer}px`;
export const HEADER_AND_FOOTER_SIZE = `${header + footer}px`;
export const MENU_SIZE = '200px';
export const BG_COLOR = mainColor;
