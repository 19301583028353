import styled from 'styled-components/macro';
import ReactSelect, { Creatable as SelectCreatable } from 'react-select';
import { lighten, desaturate, transparentize } from 'polished';

import { compose } from 'ramda';
import { color } from '../theme';

const neutralLightColor = compose(
  desaturate(0.3),
  lighten(0.6),
  color()
);

const Select = styled(ReactSelect).attrs({ clearable: false })`
  flex: 0 1 auto;

  &.is-focused:not(.is-open) > .Select-control {
    border-color: ${neutralLightColor};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px
        ${compose(
          transparentize(0.9),
          color(2)
        )};
  }

  & .Select-control {
    border-color: ${neutralLightColor};
    border-radius: 2px;
  }
  & .Select-menu-outer {
    border-radius: 2px;
    max-height: 282px;
  }

  & .Select-menu {
    max-height: 280px;
  }

  & .Select-option {
    border-radius: 0px;
  }

  & .Select-option.is-selected {
    background-color: ${color(1)};
  }

  & .Select-option.is-focused {
    background-color: ${compose(
      lighten(0.5),
      color()
    )};
  }
`;

export default Select;
export const Creatable = styled(Select.withComponent(SelectCreatable))`
  & .Select-value {
    color: ${color()};
    border-color: ${color()};
    background-color: ${props => (props.multi ? neutralLightColor(props) : 'transparent')}};
  }

  & .Select-value-icon:hover {
    background-color: ${compose(
      lighten(0.5),
      color()
    )};
    color: ${color()};
  }

  & .Select-value-icon {
    border-right-color: ${color()};
  }
`;
