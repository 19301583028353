import React from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderStyle: 'none',
    transform: 'translate(-50%, -50%)',
    borderRadius: '2px',
    maxHeight: '95%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10000',
  },
};

// eslint-disable-next-line
export default class Modal extends React.Component {
  render() {
    const { title, children, fixedSize, ...rest } = this.props;

    const style = { ...customStyles };
    if (fixedSize) {
      style.content.overflow = 'visible';
    }

    return (
      <div>
        <ReactModal ariaHideApp={false} {...rest} style={style}>
          {children}
        </ReactModal>
      </div>
    );
  }
}
