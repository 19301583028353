/* global window */
import axios from 'axios';

const isProd = window.location.hostname === 'studyplan.instoa.com';

export default axios.create({
  // baseURL: `http://localhost:5000`,
  baseURL: isProd ? `https://api-studyplan.instoa.com` : `https://dev-api-patients.instoa.com`,
  withCredentials: true,
});
