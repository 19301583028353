import service from './service';

export const logout = () => service.get('logout');

export const login = token => service.get(`login/${token}`);

export const requestLogin = (email, lang = 'en') => service.post(`login`, { email, lang });

export const register = (email, name, lang = 'en') =>
  service.post(`register`, { email, name, lang });
