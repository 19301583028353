import React from 'react';
import styled from 'styled-components/macro';
import DatePicker from 'react-datepicker';
import { Portal } from 'react-portal';

import './datepicker-styles/main.scss';

const PopperPortal = props => <Portal {...props} />;

export default styled(DatePicker).attrs(props =>
  props.highlightDates
    ? { popperContainer: PopperPortal, calendarClassName: 'partial-pick' }
    : { popperContainer: PopperPortal }
)``;
