import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styled, { css } from 'styled-components/macro';

import { desaturate, lighten } from 'polished';
import { compose } from 'ramda';

import { InlineButton } from './Button.jsx';
import { StyledInput, StyledTextarea } from './FormStyles.jsx';
import TextWithLineReturn from './TextWithLineReturn.jsx';

import { mainColor } from '../theme';
import Pencil from '../icons/Pencil.jsx';

const neutralLightColor = compose(
  desaturate(0.3),
  lighten(0.4)
)(mainColor);

const InlineForm = styled.form`
  display: inline;
  width: 100%;
`;

const styleNoValue = ({ hasValue }) => {
  if (hasValue) return '';
  return css`
    font-variant: italics;
    color: ${neutralLightColor};
  `;
};

const StyledSpan = styled.span`
  padding: 4px 0;
  display: inline-block;
  ${styleNoValue};
`;

const StyledPre = styled(TextWithLineReturn)`
  ${styleNoValue};
`;

const AlignedStyledInput = styled(StyledInput)`
  margin-left: -4px;
  width: calc(100% + 8px);
`;

const AlignedStyledTextarea = styled(StyledTextarea)`
  margin-left: -4px;
  width: calc(100% + 8px);
`;

const EditableWrapper = styled.span`
  padding-right: 1.2em;
  position: relative;
`;

const EditButton = styled(InlineButton)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default React.memo(({ value, onChange, noValueTxt = 'no value', multiline }) => {
  const [isActive, setActive] = useState(false);
  const [innerValue, setInnerValue] = useState(value);

  const inputRef = useRef(null);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useLayoutEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef.current, isActive]);

  const doneEditing = () => {
    setActive(false);
    onChange(innerValue);
    setInnerValue(value);
  };

  const cancelEditing = () => {
    if (!isActive) return;
    setActive(false);
    setInnerValue(value);
  };

  const hasValue = !!value;

  let TextContext = StyledSpan;
  let Input = AlignedStyledInput;
  if (multiline) {
    TextContext = StyledPre;
    Input = AlignedStyledTextarea;
  }

  if (!isActive)
    return (
      <EditableWrapper>
        <TextContext
          hasValue={hasValue}
          tabIndex="0"
          onClick={() => setActive(true)}
          onFocus={() => setActive(true)}
        >
          {hasValue ? value : noValueTxt}
        </TextContext>
        <EditButton onClick={() => setActive(true)}>
          <Pencil />
        </EditButton>
      </EditableWrapper>
    );
  return (
    <InlineForm onSubmit={doneEditing}>
      <Input
        value={innerValue}
        ref={inputRef}
        onChange={e => setInnerValue(e.target.value)}
        onKeyUp={e => {
          if (e.keyCode === 27) cancelEditing();
        }}
        onBlur={doneEditing}
        type="text"
      />
    </InlineForm>
  );
});
