import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ellipsis } from 'polished';

import { observer } from 'mobx-react-lite';

import Remove from '../icons/Remove.jsx';
import Modal from '../components/Modal.jsx';
import ConfirmationDialogButton from '../components/ConfirmationDialogButton.jsx';
import SideMenu from '../components/SideMenu.jsx';
import EditableText from '../components/EditableText.jsx';

import { CancelButton, ActionButton, ButtonBar, TextButton } from '../components/Button.jsx';

import useToggle from '../quarolib/hooks/useToggle';

const TeamMember = styled.div`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 0.3em;
  }
`;

const Member = styled.span`
  ${ellipsis()}
`;

const TeamMembers = styled.div`
  font-size: smaller;
  margin-top: 1em;
`;

export default observer(({ study }) => {
  const [isOpen, , , toggle] = useToggle(false);
  const [newMember, setNewMember] = useState('');

  useEffect(() => {
    study.refreshTeam();
  }, [study, isOpen]);

  return (
    <SideMenu isOpen={isOpen} onClick={toggle} title="Team">
      <TeamMembers>
        {study.team.map(member => (
          <TeamMember key={member}>
            <Member>{member}</Member>
            <ConfirmationDialogButton
              ButtonComponent={TextButton}
              text={<Remove width="1.4em" />}
              confirmText="Remove"
              onAccept={() => study.removeTeamMember(member)}
            >
              <p>
                Are you sure you want to remove access to this study to <b>{member}</b>?
              </p>
            </ConfirmationDialogButton>
          </TeamMember>
        ))}
        <TeamMember key="new member">
          <EditableText
            value=""
            noValueTxt="new team member"
            onChange={email => {
              if (email) {
                setNewMember(email);
              }
            }}
          />
          {newMember && (
            <Modal isOpen onRequestClose={() => setNewMember('')}>
              <p>
                Are you sure you want to send an invite email to <b>{newMember}</b>?
              </p>
              <ButtonBar>
                <CancelButton onClick={() => setNewMember('')}>Cancel</CancelButton>
                <ActionButton
                  onClick={() => {
                    study.addTeamMember(newMember);
                    setNewMember('');
                  }}
                >
                  Invite Member
                </ActionButton>
              </ButtonBar>
            </Modal>
          )}
        </TeamMember>
      </TeamMembers>
    </SideMenu>
  );
});
