import { useEffect, useRef } from 'react';
import { useObserver } from 'mobx-react-lite';

export default (match, study) => {
  const patient = useRef(null);

  const missingId = !match || !match.params || !match.params.patientId;

  let patientId = null;
  if (!missingId) {
    patientId = match.params.patientId;
  }

  useEffect(() => {
    if (!study || missingId) return;
    study.refreshPatient(patientId);
  }, [!!study, missingId, patientId]);

  useObserver(() => {
    if (!study || missingId) return;
    patient.current = study.patients.get(match.params.patientId);
  }, [study, missingId, patientId]);

  return patient.current;
};
