import styled from 'styled-components/macro';

export default styled.pre`
  padding: 4px 0;
  display: inline-block;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
