import React from 'react';
import styled from 'styled-components/macro';
import { Route, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { darken } from 'polished';

import Navigation from './Navigation.jsx';

import useStudy from '../hooks/useStudy';
import usePatient from '../hooks/usePatient';
import useStudyHistory from '../hooks/useStudyHistory';

import { useStore } from '../state/store';

import Logo from '../icons/Logo.jsx';

import urlTo from '../urls';

const Title = styled.div`
  user-select: none;
  display: flex;
  margin-left: auto;

  align-items: center;

  height: 50px;

  & > * {
    flex: 0 0 auto;
  }

  & > :not(:first-child) {
    margin-left: 10px;
  }
`;

const Separator = styled.div`
  width: 100%;
  flex: 0 0 auto;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 0;
  border-bottom: solid 1px ${darken(0.2, 'white')};
  content: '';
`;

const Breadcrumbs = styled.div`
  display: flex;

  align-items: center;

  height: 50px;

  & > * {
    flex: 0 0 auto;
  }

  margin-left: 1.5em;
`;

const Breadcrumb = styled(Navigation)`
  &.active {
    cursor: pointer;
  }
`;

const StudyBreadCrumb = observer(({ match }) => {
  const study = useStudy(match);
  if (!study) return null;
  return <Breadcrumb to={urlTo('study', { study })}>{study.name}</Breadcrumb>;
});

const PatientBreadCrumb = observer(({ match }) => {
  const study = useStudy(match);
  const patient = usePatient(match, study);
  if (!study) return null;
  if (!patient) return null;
  return <Breadcrumb to={urlTo('study', { study, patient })}>{patient.name}</Breadcrumb>;
});

export const HeaderMenu = () => (
  <>
    <Breadcrumbs>
      <Breadcrumb to="/studies">Studies</Breadcrumb>
      <Route path={urlTo('study')} component={StudyBreadCrumb} />
      <Route
        path={urlTo('visits')}
        component={() => (
          <Breadcrumb as="span" className="active">
            Configuration
          </Breadcrumb>
        )}
      />
      <Route path={urlTo('patient')} component={PatientBreadCrumb} />
    </Breadcrumbs>
    <Title>
      <Logo height="30px" />
    </Title>
  </>
);

export const SideMenu = withRouter(
  observer(() => {
    const [recentHistory, ,] = useStudyHistory();
    const GlobalUI = useStore();
    if (!GlobalUI.backend.loggedIn) {
      return <Navigation to={urlTo('login')}>Login</Navigation>;
    }

    return (
      <>
        <Navigation to={urlTo('studies')}>Studies</Navigation>
        {recentHistory.map(study => (
          <Navigation key={study.id} to={urlTo('study', { study })}>
            {study.name}
          </Navigation>
        ))}
        <Separator />
        <Navigation to={urlTo('login')}>Log out</Navigation>
      </>
    );
  })
);
