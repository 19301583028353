/* global MutationObserver */
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import styled from 'styled-components/macro';

import { Button } from './Button.jsx';
import Caret from '../icons/Caret.jsx';

import { neutralLightColor } from '../theme';

const getHeight = el => {
  if (!el) return 0;
  return el.scrollHeight;
};

const useNodeHeight = ref => {
  const [height, setHeight] = useState(getHeight(ref ? ref.current : 0));

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (ref.current) {
      setHeight(getHeight(ref.current));
    }

    let mutationObserver = new MutationObserver(() => {
      const newHeight = getHeight(ref.current);
      if (newHeight !== height) setHeight(newHeight);
    });
    mutationObserver.observe(ref.current, {
      subtree: true,
      characterData: true,
      attributes: true,
      childList: true,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      mutationObserver.disconnect(ref.current);
      mutationObserver = null;
    };
  }, [ref.current]);

  return height;
};

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const useAccordeon = (ref, isOpen) => {
  const height = useNodeHeight(ref);

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const transitionning = useRef(false);

  const previousIsOpen = usePrevious(isOpen);
  const previousHeight = usePrevious(height);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const transitionFinished = () => {
      transitionning.current = false;
      setShouldRefresh(!shouldRefresh);
    };
    ref.current.addEventListener('transitionend', transitionFinished);

    // eslint-disable-next-line consistent-return
    return () => {
      transitionning.current = false;
      ref.current.removeEventListener('transitionend', transitionFinished);
    };
  }, [isOpen, ref.current, height]);

  if (isOpen !== previousIsOpen || height !== previousHeight) {
    transitionning.current = true;
  }

  const overflow = transitionning.current ? 'hidden' : null;

  if (!isOpen) {
    return {
      overflow,
      opacity: 0,
      maxHeight: 0,
      pointerEvents: 'none',
    };
  }

  return {
    overflow,
    opacity: 1,
    maxHeight: height,
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${neutralLightColor};
  border-radius: 2px;
  padding: 1em;

  margin-bottom: auto;
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  width: 265px;
`;

const MyButton = styled(Button)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    border-color: transparent;
    color: inherit;
    fill: inherit;
    background-color: inherit;
  }
`;

const turnOnOpen = ({ isOpen }) => {
  if (!isOpen) return '';
  return `
    transform: rotate(-90deg);
  `;
};
const NiceCaret = styled(Caret)`
  position: absolute;
  transition: transform 0.3s ease-out;
  right: 5px;

  ${turnOnOpen};
`;

export const HeaderButton = ({ children, isOpen, ...props }) => (
  <MyButton {...props}>
    {children}
    <NiceCaret isOpen={isOpen} />
  </MyButton>
);

const Accordeon = styled.div`
  transition: 0.3s all ease-out;
`;

export default ({ title, isOpen, onClick, children }) => {
  const node = useRef(null);
  const styles = useAccordeon(node, isOpen);

  return (
    <Wrapper>
      <HeaderButton onClick={onClick} isOpen={isOpen}>
        {title}
      </HeaderButton>
      <Accordeon style={styles} ref={node}>
        {children}
      </Accordeon>
    </Wrapper>
  );
};
