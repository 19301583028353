import React from 'react';

import { ButtonBar, CancelButton, ActionButton, Button } from './Button.jsx';
import useToggle from '../quarolib/hooks/useToggle';

import Modal from './Modal.jsx';

export default ({ text = 'Delete', children, onAccept, ButtonComponent = Button, confirmText }) => {
  const [isOpen, open, close] = useToggle(false);

  return (
    <>
      <ButtonComponent onClick={open}>{text}</ButtonComponent>
      {isOpen && (
        <Modal isOpen onRequestClose={close}>
          {children}
          <ButtonBar>
            <CancelButton onClick={close}>Cancel</CancelButton>
            <ActionButton
              onClick={() => {
                onAccept();
                close();
              }}
            >
              {confirmText || text}
            </ActionButton>
          </ButtonBar>
        </Modal>
      )}
    </>
  );
};
