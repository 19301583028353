import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';

import { Formik, Form } from 'formik';
import { CancelButton, ActionButton, ButtonBar } from '../components/Button.jsx';
import Modal from '../components/Modal.jsx';

import StudyForm from '../parts/StudyForm.jsx';
import StyledLink from '../components/StyledLink.jsx';

import { useStore } from '../state/store';
import useToggle from '../quarolib/hooks/useToggle';

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 100%;
`;

const StudyWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  padding: 10px;

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StudiesSection = styled.div`
  margin-right: 30px;
  flex: 1 1 0px;
`;

const NewStudy = ({ isSubmitting, cancel }) => (
  <FormBody as={Form}>
    <h4>New Study</h4>
    <StudyForm />
    <ButtonBar>
      <CancelButton
        type="button"
        onClick={e => {
          e.preventDefault();
          cancel();
        }}
      >
        Cancel
      </CancelButton>
      <ActionButton disable={isSubmitting} type="submit">
        Save
      </ActionButton>
    </ButtonBar>
  </FormBody>
);

const Sponsor = styled.span`
  font-size: smaller;
  margin-left: 2em;
  opacity: 0.8;
`;

const Study = observer(({ study }) => (
  <StudyWrapper>
    <StyledLink to={`/studies/${study.id}/`}>{study.name}</StyledLink>
    <Sponsor>{study.sponsor}</Sponsor>
  </StudyWrapper>
));

export default withRouter(
  observer(() => {
    const [isOpen, open, close] = useToggle(false);

    const { studies } = useStore();

    useEffect(() => studies.refreshAll(), [studies]);

    const newStudy = (values, actions) => {
      actions.setSubmitting(true);
      studies.newStudy(values).then(() => {
        actions.setSubmitting(false);
        close();
      });
    };

    return (
      <Columns>
        <StudiesSection>
          {studies && studies.all.map(study => <Study key={study.id} study={study} />)}
        </StudiesSection>

        <div>
          <ActionButton onClick={open}>New Study</ActionButton>
          <Modal isOpen={isOpen} onRequestClose={close}>
            <Formik
              initialValues={{ name: '', sponsor: '' }}
              onSubmit={newStudy}
              render={props => <NewStudy {...props} cancel={close} />}
            />
          </Modal>
        </div>
      </Columns>
    );
  })
);
