import React from 'react';

export default ({ width = '1em' }) => (
  <svg width={width} viewBox="0 0 164 164">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.883 155.452h126.916V164H7.883v-8.548zm9.043-44.638l-4.667 19.585 13.844 13.843 19.423-4.827.155-.155-28.601-28.601-.154.155zm32.175 25.027l-28.601-28.6 86.114-86.115 28.601 28.602-86.114 86.113zm89.533-89.533l6.219-6.218-28.601-28.601-6.219 6.219 28.601 28.6zm9.637-9.638l4.976-4.974c11.219-11.22-17.584-39.619-28.135-29.067l-5.44 5.44 28.599 28.601zM10.944 135.922l9.683 9.682-12.744 3.166 3.061-12.848z"
    />
  </svg>
);
