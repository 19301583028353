import styled from 'styled-components/macro';

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MainSection = styled.div`
  margin-right: 1em;
  flex: 1 1 0px;
`;

export const MainBlock = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 2em;
  }

  :nth-child(odd) {
    background-color: #f9f9f9;
  }

  padding: 1em 0.4em 0.4em 1em;
  border-radius: 2px;
`;

export const BlockTitle = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: bigger;
  display: flex;
  align-items: center;

  margin-bottom: 0.6em;
`;

export const SubBlock = styled.div`
  width: calc(265px - 0.6em);
  max-width: calc(265px - 0.6em);
  margin-bottom: 0.6em;
  margin-right: 0.6em;
`;

export const ShortSubBlock = styled(SubBlock)`
  width: 132px;
  max-width: 132px;
`;
