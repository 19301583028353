import { map, sorted, max } from 'itertools';
import uuid from 'uuid/v4';
import { decorate, computed, action } from 'mobx';
import sortBy from 'lodash/sortBy';

import { Field, FieldMapper } from '../storelib/fieldMapper';
import FieldStore, { BaseFieldStore } from '../storelib/storeField';

const ChecklistItem = new FieldMapper({
  id: new Field({ create: id => id || uuid() }),
  content: new Field({ val: '' }),
  order: new Field({ val: 0 }),
});

class RawChecklist extends BaseFieldStore {
  fieldMapper = ChecklistItem;

  get all() {
    return sorted(map(this.raw.keys(), key => this.get(key)), c => c.order);
  }

  get maxOrder() {
    return (max(this.all, v => v.order) || { order: 0 }).order;
  }
}
const Checklist = decorate(RawChecklist, { maxOrder: computed, all: computed });

const extractPeriod = ({ value = 0, unit = 'week' }) => ({ value, unit });
const updatePeriod = (newPeriod, previousPeriod) => {
  if (newPeriod.value === previousPeriod.value && newPeriod.unit === previousPeriod.unit) {
    return previousPeriod;
  }
  return newPeriod;
};
export const VisitModel = new FieldMapper({
  id: new Field({ create: id => id || uuid() }),
  name: new Field({ val: '' }),

  optional: new Field({ val: false }),

  after_visit: new Field({ val: '' }),
  after_delay: new Field({
    val: { value: 2, unit: 'month' },
    extract: extractPeriod,
    update: updatePeriod,
  }),
  window: new Field({
    val: { value: 1, unit: 'week' },
    extract: extractPeriod,
    update: updatePeriod,
  }),

  comment: new Field({ val: '' }),
  checklist: new FieldStore(config => new Checklist(config)),
});

const MULTIPLIER = {
  day: 1,
  week: 7,
  month: 30,
  year: 365,
};

class Visits extends BaseFieldStore {
  fieldMapper = VisitModel;

  get count() {
    return this.raw.size;
  }

  itemView() {
    return [
      {
        visits: this,
        get positionHelper() {
          if (!this.after_visit) return 0;
          const delay = this.after_delay.value * MULTIPLIER[this.after_delay.unit];
          const dependant = this.visits.get(this.after_visit);

          if (!dependant) return 0;
          return dependant.positionHelper + delay;
        },

        get hasDependants() {
          return !!this.visits.all.find(visit => visit.after_visit === this.id);
        },

        newChecklistItem(content) {
          this.checklist.insert({
            content,
            order: this.checklist.maxOrder + 1,
          });
        },
      },
      {
        positionHelper: computed,
        newChecklistItem: action.bound,
      },
    ];
  }

  get all() {
    const allVisits = map(this.raw.keys(), key => this.get(key));

    const sortedVisits = sortBy(allVisits, visit =>
      visit.optional ? -1e10 : -visit.positionHelper
    );
    sortedVisits.reverse();
    return sortedVisits;
  }

  map(mapper) {
    return map(this.all, k => mapper(k));
  }
}

export default decorate(Visits, {
  all: computed,
});
