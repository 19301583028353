import React, { useRef } from 'react';
import styled from 'styled-components/macro';

import Menu from './Menu.jsx';
import { HEADER_SIZE, BG_COLOR } from './constants';

import MenuIcon from '../icons/Menu.jsx';

import useOutsideCallback from '../quarolib/hooks/useOutsideCallback';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  z-index: 2000;

  padding-left: 20px;
  padding-right: 20px;

  height: ${HEADER_SIZE};
  min-height: ${HEADER_SIZE};
  line-height: ${HEADER_SIZE};

  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: ${BG_COLOR};
  color: white;
  fill: white;
  stroke: white;

  & > * {
    flex: 0 0 auto;
  }

  @media print {
    display: none;
  }
`;

const MenuButtonWrapper = styled.div`
  flex: 0 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;

  cursor: pointer;

  user-select: none;
  white-space: nowrap;
`;

const PlacedMenu = styled(({ opened, ...props }) => <MenuIcon {...props} />)`
  top: 5px;
  position: relative;
  ${props => props.opened && 'transform: rotate(180deg); fill: white;'};
  transition: all 300ms ease-out;
`;

export default ({ closeMenu, toggleMenu, menuOpen, sideMenu, headerMenu }) => {
  const sideMenuRef = useRef(null);
  const headerRef = useRef(null);

  useOutsideCallback([sideMenuRef, headerRef], closeMenu);

  return (
    <>
      <Menu open={menuOpen} innerRef={sideMenuRef}>
        {sideMenu}
      </Menu>
      <HeaderWrapper ref={headerRef}>
        <MenuButtonWrapper onClick={toggleMenu}>
          <PlacedMenu opened={menuOpen} width="1.3em" />
        </MenuButtonWrapper>
        {headerMenu}
      </HeaderWrapper>
    </>
  );
};
