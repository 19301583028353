import React from 'react';

import styled from 'styled-components/macro';
import { lighten } from 'polished';

import { HEADER_SIZE, MENU_SIZE, BG_COLOR } from './constants';

const SlidingMenuWrapper = styled.div`
  position: fixed;

  top: ${HEADER_SIZE};
  bottom: 0px;

  left: -${MENU_SIZE};
  overflow: auto;

  background-color: ${lighten(0.05, BG_COLOR)};
  ${props => props.open && `transform: translateX(${MENU_SIZE});`};

  will-change: transform;
  transition: all 300ms ease-out;
  box-shadow: inset -10px 0px 20px -10px rgba(0, 0, 0, 0.75);

  max-width: ${MENU_SIZE};
  width: 100%;
`;

const InnerMenu = styled.div`
  padding: 20px;

  color: white;
  fill: white;

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  & > * {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

/* This is where you can add new entry points */
export default ({ open, children, innerRef, ...props }) => (
  <SlidingMenuWrapper ref={innerRef} open={open} {...props}>
    <InnerMenu>{children}</InnerMenu>
  </SlidingMenuWrapper>
);
