import styled, { css } from 'styled-components/macro';

import { desaturate, lighten, transparentize } from 'polished';
import { compose } from 'ramda';
import { mainColor } from '../theme';

import Arrow from '../icons/Arrow.svg';

const neutralLightColor = compose(
  desaturate(0.3),
  lighten(0.4)
)(mainColor);

const inputStyle = css`
  padding: 3px;
  width: 100%;
  border: solid 1px ${neutralLightColor};

  appearance: none;
  outline: none;

  font-weight: inherit;
  font-variant: inherit;

  border-radius: 2px;

  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: ${neutralLightColor};
    box-shadow: 0px 0px 1px ${mainColor};
  }
  &:disabled {
    border-color: ${neutralLightColor};
    background-color: ${transparentize(0.7, neutralLightColor)};
  }
`;

export const StyledInput = styled.input`
  ${inputStyle}
`;

export const StyledTextarea = styled.textarea`
  ${inputStyle}
`;

export const StyledSelect = styled.select`
  display: inline;
  width: 100%;
  color: #444;
  line-height: 1.15;

  padding: 3px 2em 3px 3px;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  margin: 0;
  border: solid 1px ${neutralLightColor};

  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url(${Arrow}), linear-gradient(to bottom, transparent 0%, transparent 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #888;
  }
  &:focus {
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    outline: none;
  }

  /* Set options to normal weight */
  & option {
    font-weight: normal;
  }
`;
