/* global navigator */

import { observable, action } from 'mobx';

import { login, logout } from '../service/login';

export default observable(
  {
    offline: !navigator.onLine,
    loggedIn: false,

    waitFor: null,
    connectionError: null,

    clearErrors() {
      this.connectionError = null;
    },
    reportError(error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.connectionError = error;
    },

    query(run, authed = true) {
      return run()
        .then(value => {
          if (authed) this.loggedIn = true;
          return value;
        })
        .catch(error => {
          if (authed && error.response && error.response.status === 403) {
            this.loggedIn = false;
          }
          this.reportError(error);
          throw error;
        });
    },

    login(token) {
      return login(token).then(() => {
        this.loggedIn = true;
      });
    },
    logout() {
      return logout().then(() => {
        this.loggedIn = false;
      });
    },
  },

  {
    clearErrors: action.bound,
    reportError: action.bound,

    query: action.bound,

    login: action.bound,
    logout: action.bound,
  }
);
