/* global window navigator alert */
import { observable, action, autorun } from 'mobx';
import querystring from 'querystring';

import backend from './backend';
import makeStudies from './studies';
import history from './history';

const HISTORY_NAME = 'STUDY_HISTORY';

const watchOfflineStatus = ui => {
  window.addEventListener(
    'offline',
    () => {
      // eslint-disable-next-line
      ui.backend.offline = true;
    },
    false
  );
  window.addEventListener(
    'online',
    () => {
      // eslint-disable-next-line
      ui.backend.offline = false;
    },
    false
  );
};

const parseLang = lang => {
  if (lang.startsWith('fr')) {
    return 'fr';
  }
  return 'en';
};

const defaultLang = () => {
  const savedLang = window.localStorage.getItem('uiLang');
  if (savedLang) return savedLang;

  const browserLang = window.navigator.userLanguage || window.navigator.language;
  return parseLang(browserLang);
};

const seenStudies = () => {
  try {
    const raw = window.localStorage.getItem(HISTORY_NAME);
    if (!raw) return [];
    return JSON.parse(raw);
  } catch (e) {
    return [];
  }
};

export default () => {
  const ui = observable(
    {
      ready: false,
      filter: 'all',
      consentIsOpen: false,

      studiesSeen: seenStudies(),

      studies: makeStudies(backend),

      lang: defaultLang(),

      backend,

      login() {
        const qs = querystring.parse(history.location.search.replace(/^\?/, ''));
        if (!qs.token) return Promise.resolve();
        return this.backend.login(qs.token).then(() =>
          history.replace({
            search: '',
          })
        );
      },

      init() {
        autorun(() => {
          window.localStorage.setItem('uiLang', this.lang);
        });
        autorun(() => {
          try {
            window.localStorage.setItem(HISTORY_NAME, JSON.stringify(Array.from(this.studiesSeen)));
          } catch (e) {}
        });

        watchOfflineStatus(this);

        this.login()
          .then(() => this.studies.init())
          .then(() => {
            this.ready = true;
          })
          .catch(() => {
            this.ready = true;
          });
      },
    },
    {
      init: action.bound,
      login: action.bound,
    }
  );

  ui.init();
  return ui;
};
