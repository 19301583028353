import React from 'react';

import { Field } from 'formik';
import { Line, Label } from '../components/InfoBox.jsx';
import { StyledInput } from '../components/FormStyles.jsx';

export default () => (
  <>
    <Line>
      <Label>Name</Label>
      <StyledInput autoFocus as={Field} type="text" name="name" placeholder="Name of the study" />
    </Line>
    <Line>
      <Label>Sponsor</Label>
      <StyledInput as={Field} type="text" name="sponsor" />
    </Line>
  </>
);
