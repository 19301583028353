import styled from 'styled-components/macro';

import { FOOTER_SIZE } from './constants';

export default styled.div`
  padding-left: 20px;
  padding-right: 20px;

  flex: 0 0 100%;

  height: ${FOOTER_SIZE};
  min-height: ${FOOTER_SIZE};
  max-height: ${FOOTER_SIZE};

  line-height: ${FOOTER_SIZE};

  display: flex;
  justify-content: space-evenly;

  @media print {
    display: none;
  }
`;
