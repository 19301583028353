import React from 'react';
import { observer } from 'mobx-react-lite';
import EditableText from './EditableText.jsx';

export default observer(({ item, field, ...props }) => (
  <EditableText
    {...props}
    value={item && item[field]}
    onChange={newValue => {
      item[field] = newValue;
    }}
  />
));
