import uuid from 'uuid/v4';
import { decorate, computed, action, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { max, min } from 'itertools';
import { isBefore, startOfDay, isSameDay } from 'date-fns';

import { Field, FieldMapper } from '../storelib/fieldMapper';
import DateField from '../storelib/dateField';
import FieldStore, { BaseFieldStore } from '../storelib/storeField';

import PatientVisit from './patient-visits-history';

const startOfToday = observable.box(startOfDay(new Date()));
setInterval(() => {
  const now = new Date();
  if (isSameDay(startOfToday.get(), now)) return;
  startOfToday.set(startOfDay(now));
}, 5000);

const PatientHistoryModel = new FieldMapper({
  id: new Field({ create: id => id || uuid() }),
  visitId: new Field({ val: '' }),

  visitDate: new DateField({ val: '' }),
  cancelled: new Field({ val: false }),
  comment: new Field({ val: '' }),

  checklist: new Field({
    val: [],
    create: val => new Set(val),
    extract: val => Array.from(val),
    update: (val, prevVal) => {
      const toDel = new Set(prevVal);
      val.forEach(v => {
        prevVal.add(v);
        toDel.delete(v);
      });
      toDel.forEach(v => {
        prevVal.delete(v);
      });

      return prevVal;
    },
  }),
});

class PatientHistory extends BaseFieldStore {
  fieldMapper = PatientHistoryModel;

  forVisit(visitId) {
    return this.all.filter(history => history.visitId === visitId);
  }
}

const PatientModel = new FieldMapper({
  id: new Field({ defaults: id => id || uuid() }),
  patient_id: new Field({ val: '' }),
  name: new Field({ val: '' }),
  email: new Field({ val: '' }),
  phone: new Field({ val: '' }),
  birthdate: new DateField({ val: '' }),
  comment: new Field({ val: '' }),

  dropped: new Field({ val: false }),
  dropped_info: new Field({ val: '' }),

  history: new FieldStore(context => new PatientHistory(context)),

  signed_consent_forms: new Field({
    val: [],
    create: val => new Map(val),
    extract: val => Array.from(val),
    update: (val, prevVal) => {
      if (prevVal.merge) {
        prevVal.merge(val);
        return prevVal;
      }

      return new Map([...prevVal, val]);
    },
  }),

  additional_fields: new Field({
    val: [],
    create: val => new Map(val),
    extract: val => Array.from(val),
    update: (val, prevVal) => {
      if (prevVal.merge) {
        prevVal.merge(val);
        return prevVal;
      }

      return new Map([...prevVal, val]);
    },
  }),
});

class Patients extends BaseFieldStore {
  fieldMapper = PatientModel;

  constructor(context) {
    super(context);
    this.onChange = this.onChange.bind(this);
    this.changeOptions = { delay: 100 };
  }

  itemView(view) {
    const getVisit = createTransformer(
      visit => new PatientVisit(visit.id, this.parent.visits, view.history)
    );

    return [
      {
        patientStore: this,

        getVisit,

        get visitsHistory() {
          return this.patientStore.parent.visits.all.map(this.getVisit);
        },

        get nextUnplanned() {
          const unplanned = this.visitsHistory.filter(
            visit => !visit.current && visit.canEdit && !visit.optional
          );
          return min(unplanned, visit => (visit.window ? visit.window.start.getTime() : 0));
        },

        nextAfterDate(date) {
          const planned = this.visitsHistory.filter(
            visit => visit.current && visit.current.visitDate
          );
          const afterDate = planned.filter(visit => !isBefore(visit.current.visitDate, date));
          return min(afterDate, visit => visit.current.visitDate.getTime());
        },

        get nextAfterToday() {
          return this.nextAfterDate(startOfToday.get());
        },

        get lastPlanned() {
          const planned = this.visitsHistory.filter(
            visit => visit.current && visit.current.visitDate
          );

          return max(planned, visit => visit.current.visitDate.getTime());
        },

        dropOut() {
          this.dropped = true;
        },

        joinBack() {
          this.dropped = false;
        },

        get latestConsentForm() {
          if (!this.signed_consent_forms.size) return '';
          const [latestFormId] = max(this.signed_consent_forms.entries(), ([, date]) => date);
          if (!latestFormId || !this.patientStore.parent.consent_forms) return '';
          return this.patientStore.parent.consent_forms.get(latestFormId);
        },

        signForm(form, date) {
          if (!date && this.signed_consent_forms.has(form)) {
            this.signed_consent_forms.delete(form);
            return;
          }
          this.signed_consent_forms.set(form, date);
        },

        editField(field, value) {
          if (!value && this.additional_fields.has(field)) {
            this.additional_fields.delete(field);
            return;
          }
          this.additional_fields.set(field, value);
        },
      },
      {
        visitsHistory: computed,
        nextUnplanned: computed,
        lastPlanned: computed,
        dropOut: action.bound,
        joinBack: action.bound,
      },
    ];
  }

  onChange(key, value) {
    let reportedValue = value;
    if (!this.parentInstanceId || !this.parentStore || !this.parentStore.onChange) return;
    if (!value) {
      reportedValue = { id: key, _delete: true };
    }
    if (!reportedValue.id) {
      reportedValue.id = key;
    }
    this.parentStore.onChange(this.parentInstanceId, { [this.sourceField]: [reportedValue] });
  }
}

export default decorate(Patients, {});
