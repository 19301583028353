import { parse, format, isDate } from 'date-fns';
import { Field } from './fieldMapper';

const FORMAT = 'yyyy-MM-dd';

const parseDate = date => {
  if (!date) return date;
  if (isDate(date)) return date;
  try {
    return parse(date, FORMAT, new Date('2000-01-01T12:10:00Z'));
  } catch (e) {
    console.error('Could not parse date', date);
    return '';
  }
};

export default class DateField extends Field {
  create(fromVal = this.defaultsVal, modelContext) {
    const val = super.update(fromVal, modelContext);
    return parseDate(val);
  }

  update(newVal, previousVal) {
    const val = super.update(newVal);

    const newParsed = parseDate(val);
    if (
      newParsed &&
      previousVal &&
      previousVal.getTime &&
      newParsed.getTime() === previousVal.getTime()
    ) {
      return previousVal;
    }
    return newParsed;
  }

  extract(value) {
    const val = super.extract(value);
    if (!val) return val;
    if (!isDate(val)) return val;
    try {
      return format(val, FORMAT);
    } catch (e) {
      console.error('Could not format date', val);
      return '';
    }
  }
}
