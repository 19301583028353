import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { format } from 'date-fns';
import { desaturate, lighten } from 'polished';
import { compose } from 'ramda';
import { mainColor } from '../theme';

import { InlineButton } from './Button.jsx';
import { StyledInput } from './FormStyles.jsx';
import Pencil from '../icons/Pencil.jsx';

import DatePicker from './DatePicker.jsx';

const neutralLightColor = compose(
  desaturate(0.3),
  lighten(0.4)
)(mainColor);

const styleNoValue = ({ hasValue }) => {
  if (hasValue) return '';
  return css`
    font-variant: italics;
    color: ${neutralLightColor};
  `;
};

const StyledSpan = styled.span`
  padding: 4px 0;
  display: inline-block;
  ${styleNoValue};
`;

const AlignedStyledInput = styled(StyledInput)`
  margin-left: -4px;
  max-width: 200px;
  max-width: max-content;
`;

const EditableWrapper = styled.span`
  padding-right: 1.2em;
  position: relative;
`;

export const EditButton = styled(InlineButton)`
  position: absolute;
  top: 0.175em;
  right: 0;
`;

export default React.memo(
  ({ value, onChange, disabled, highlighted, openToDate, noValueTxt = 'no value', ...props }) => {
    const [isActive, setActive] = useState(false);

    const doneEditing = newVal => {
      setActive(false);
      onChange(newVal);
    };

    const cancelEditing = () => {
      if (!isActive) return;
      setActive(false);
    };

    const cancelOnEscape = event => {
      const eventKey = event.key;
      if (eventKey === 'Escape') {
        event.preventDefault();
        cancelEditing();
      }
    };

    const hasValue = !!value;

    if (!isActive)
      return (
        <EditableWrapper>
          <StyledSpan
            hasValue={hasValue}
            tabIndex="0"
            onClick={() => setActive(!disabled)}
            onFocus={() => setActive(!disabled)}
          >
            {hasValue ? format(value, 'dd.MM.yyyy') : noValueTxt}
          </StyledSpan>
          {!disabled && (
            <EditButton onClick={() => setActive(!disabled)}>
              <Pencil />
            </EditButton>
          )}
        </EditableWrapper>
      );

    return (
      <EditableWrapper>
        <AlignedStyledInput
          as={DatePicker}
          open
          autoFocus
          dateFormat="dd.MM.yyyy"
          disabled={disabled}
          selected={value}
          highlightDates={highlighted}
          openToDate={openToDate}
          onChange={doneEditing}
          onBlur={cancelEditing}
          onKeyDown={cancelOnEscape}
          {...props}
        />
      </EditableWrapper>
    );
  }
);
